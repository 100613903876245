import React, { Children } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { VaporCleanProps, VaporClasses, sizedClasses } from "./Vapor";
import FancyIcon from "../components/FancyIcon";

export function VaporButton({ children, isTransparent, ...props }) {
	let icon = null;
	if (props.icon) {
		icon = <FancyIcon icon={props.icon} />;
	}

	return (
		<div className={classNames("vapor_button", { transparent: isTransparent, icon: props.icon })}>
			<button
				className={classNames(VaporClasses(props))}
				{...VaporCleanProps(props, [...Object.keys(sizedClasses), ...Object.keys(VaporButton.defaultProps)])}
			>
				{icon}
				{children}
			</button>
		</div>
	);
}
VaporButton.propTypes = {
	children: PropTypes.any,
	isTransparent: PropTypes.bool,
};
VaporButton.defaultProps = {
	doubleConfirm: false,
	isTransparent: false,
	marginTop: 100,
	marginBottom: 100,
	padding: 200,
	icon: null,
};

export function VaporInlineButton({ children, ...props }) {
	return (
		<div className={classNames("vapor_inline_button")}>
			<button {...props}>{children}</button>
		</div>
	);
}

export function VaporButtonList(props) {
	return (
		<div className={"vapor_button_list"}>
			<ul className={classNames("vapor_flex_justify_" + props.flexJustify, props.direction, VaporClasses(props))}>
				{Children.toArray(props.children).map((child, i) => {
					return <li key={i}>{child}</li>;
				})}
			</ul>
		</div>
	);
}
VaporButtonList.defaultProps = {
	marginTop: 100,
	marginBottom: 100,
	direction: "horizontal",
	flexJustify: "center",
};
