import React, { Children, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import FancyIcon from "../../components/FancyIcon";
import classNames from "classnames";
import Particles from "react-tsparticles";
import { VaporParticles } from "../Vapor.js";
import "./Layouts.scss";

export function VaportLayoutFullScreen(props) {
	return (
		<div className={"vapor_layout_full_screen vapor_scanlines vapor_scanlines_flicker"}>
			{/* <VaporParticles /> */}
			{props.children}
		</div>
	);
}

export function VaporLayoutCenter(props) {
	return (
		<div className={"vapor_layout_center"}>
			{/* <VaporParticles /> */}
			{props.children}
		</div>
	);
}

export function VaporLayoutCenterOld(props) {
	return <div className={"vapor_layout_center vapor_scanlines vapor_scanlines_flicker"}>{props.children}</div>;
}
