import React, { useEffect, useState } from "react";
import { Settings } from "../../settings.js";
import { apiCall } from "../../authentication";
import WorldMap from "../../components/WorldMap";
import {
	VaporLayoutCenter,
	VaporWindow,
	VaporWindowTitle,
	VaporWindowContent,
	VaporInput,
	VaporButton,
	VaporButtonList,
	VaporInlineButton,
	VaporWindowDivider,
	VaporParticles,
} from "../../Design/Vapor";
import "./style.scss";

function IpInformation(props) {
	return (
		<div className={"SignInNew"}>
			<VaporLayoutCenter>
				<VaporParticles />
				<VaporWindow>
					<VaporWindowTitle icon={null}>Client IP Address</VaporWindowTitle>
					<VaporWindowContent>
						<IpInfo />
					</VaporWindowContent>
				</VaporWindow>
			</VaporLayoutCenter>
		</div>
	);
}

function IpInfo(props) {
	const [ipData, setIpData] = useState(null);
	document.title = Settings.TITLE_PREFIX + "IP Lookup";

	const fetchApi = async () => {
		let response = await apiCall("GET", `/v1/utilities/ip/`);
		setIpData(response.data);
	};

	useEffect(() => {
		fetchApi();
	}, []); // eslint-disable-line

	if (!ipData) {
		return <div>Loading...</div>;
	} else {
		let location = <div>Unknown Location</div>;
		if (ipData.maxmind_city) {
			location = (
				<div>
					<dt>Location</dt>
					<dd>
						{ipData.maxmind_city.city && ipData.maxmind_city.city.names.en}
						{ipData.maxmind_city.subdivisions && ", " + ipData.maxmind_city.subdivisions[0].names.en + ", "}
						{ipData.maxmind_city.country && ipData.maxmind_city.country.names.en}
						<br />
						{ipData.maxmind_city.location.latitude}&deg;/{ipData.maxmind_city.location.longitude}&deg;
					</dd>
				</div>
			);
		}

		return (
			<div>
				<dl>
					<dt>IP Address</dt>
					<dd>
						<span className="ip">{ipData.ip_address}</span> (ASN:{" "}
						<a
							href={
								"https://mxtoolbox.com/SuperTool.aspx?action=asn%3a" +
								ipData.maxmind_asn.autonomous_system_number +
								"&run=toolpage"
							}
							target="_blank"
							rel="noreferrer"
						>
							{ipData.maxmind_asn.autonomous_system_number}
						</a>{" "}
						{ipData.maxmind_asn.autonomous_system_organization})
					</dd>
					{location}
					{ipData.maxmind_city ? (
						<WorldMap
							center={[ipData.maxmind_city.location.longitude, ipData.maxmind_city.location.latitude]}
							markers={[
								{
									label: "GEOIP",
									coordinates: [
										ipData.maxmind_city.location.longitude,
										ipData.maxmind_city.location.latitude,
									],
								},
							]}
						/>
					) : (
						<>No location to map.</>
					)}
					{/* <dt>User Agent</dt>
					<dd>{ipData['user-agent']}</dd> */}
				</dl>
			</div>
		);
	}
	// {ipData.maxmind_city.city.names.en}, {ipData.maxmind_city.subdivisions[0].names.en}, {ipData.maxmind_city.country.names.en}<br />{ipData.maxmind_city.location.latitude}&deg;/{ipData.maxmind_city.location.longitude}&deg;
}

export default IpInformation;
