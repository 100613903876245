import React from 'react';
import { useAuthDispatch, logout, useAuthState } from '../../authentication';
import styles from './dashboard.module.css';
import { useNavigate } from 'react-router-dom';
import LayoutDefault from '../../layouts/default.js';

function Dashboard(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
    let navigate = useNavigate();
    
	console.log(userDetails)

	const handleLogout = () => {
		logout(dispatch);
        navigate('/login');
	};
	return (
		<LayoutDefault>
			<div style={{ padding: 10 }}>
				<div className={styles.dashboardPage}>
					<h1>Dashboard</h1>
					<button className={styles.logoutBtn} onClick={handleLogout}>
						Logout
					</button>
				</div>
				<p>Welcome {userDetails.user.email}</p>
			</div>
		</LayoutDefault>
	);
}

export default Dashboard;