import { EditorView, Decoration, ViewPlugin } from "@codemirror/view";
import { RangeSetBuilder } from "@codemirror/state";
import { syntaxTree } from "@codemirror/language";

// Define a decoration mark that applies the spellcheck=false HTML attribute
const spellCheckDisabled = Decoration.mark({
	attributes: { spellcheck: false },
});

function spellcheckDisabledDeco(view) {
	const builder = new RangeSetBuilder();

	for (let { from, to } of view.visibleRanges) {
		syntaxTree(view.state).iterate({
			from,
			to,
			enter: (type, from, to, ...props) => {
				//console.log(type);
				// console.log(from);
				// console.log(to);
				//console.log(view.state.doc.sliceString(from, to));
				// Add the spellcheck disabled decoration for commands
				if (type.name === "typeName") {
					builder.add(from, to, spellCheckDisabled);
				}
			},
		});
	}

	return builder.finish();
}

const disableSpellcheckForCommands = ViewPlugin.fromClass(
	class {
		constructor(view) {
			this.decorations = spellcheckDisabledDeco(view);
		}

		update(update) {
			// This probably should be optimised, the zebra stripes example (https://codemirror.net/6/examples/zebra/)
			// mentions mapping the ranges
			if (update.docChanged || update.viewportChanged) {
				this.decorations = spellcheckDisabledDeco(update.view);
			}
		}
	},
	{
		decorations: (value) => value.decorations,
	}
);

export function browserSpellCheck() {
	return [
		// Globally apply the spellcheck=true attribute
		EditorView.contentAttributes.of({ spellcheck: true }),
		// Disable the spellcheck attribute for commands
		disableSpellcheckForCommands,
	];
}
