export const Duplicate = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="paste"
				d="M14,13.5v1a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7A.5.5,0,0,1,14,13.5ZM13.5,9h-7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,9ZM18,5V17a2.0026,2.0026,0,0,1-2,2H4a2.0026,2.0026,0,0,1-2-2V5A2.0026,2.0026,0,0,1,4,3H7l.72363-1.4472A.99992.99992,0,0,1,8.618,1H11.382a.99992.99992,0,0,1,.89441.5528L13,3h3A2.0026,2.0026,0,0,1,18,5ZM16.002,17,16,5H14V6a1,1,0,0,1-1,1H7A1,1,0,0,1,6,6V5H4V17Z"
			/>
		</svg>
	);
};

export const StarEmpty = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="star-empty"
				d="M18.00214,7H12.3634L10.62128,1.63544a.65.65,0,0,0-1.23645,0L7.64276,7H2.004a.65.65,0,0,0-.382,1.1759l4.56268,3.31415L4.44489,16.84766a.65215.65215,0,0,0,.62042.85369.64078.64078,0,0,0,.37976-.127l4.558-3.31073,4.558,3.31073a.64087.64087,0,0,0,.37976.127.65214.65214,0,0,0,.62048-.85369l-1.73981-5.35761L18.38416,8.1759A.65.65,0,0,0,18.00214,7Zm-6.53193,3.72559.90577,2.79L10.00293,11.792l-2.373,1.72363.90576-2.79L6.15918,9H9.0957l.90723-2.793L10.91016,9h2.93652Z"
			/>
		</svg>
	);
};

export const Star = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="star"
				d="M18.38416,8.1759l-4.56269,3.31415,1.73981,5.35761a.65214.65214,0,0,1-.62048.85369.64087.64087,0,0,1-.37976-.127l-4.558-3.31073-4.558,3.31073a.64091.64091,0,0,1-.37976.127.65222.65222,0,0,1-.62049-.85369l1.73981-5.35761L1.622,8.1759A.65.65,0,0,1,2.004,7H7.64276L9.38483,1.63544a.65.65,0,0,1,1.23645,0L12.36334,7h5.6388A.65.65,0,0,1,18.38416,8.1759Z"
			/>
		</svg>
	);
};

export const Palette = (props) => {
	return (
		<svg {...props} id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path
				id="palette"
				d="M31.007,14.21729l-5.38477,6.92919a8.19948,8.19948,0,0,1-1.46917,1.3252l-1.13483.75732L20.271,20.48193l.75714-1.13476a8.20677,8.20677,0,0,1,1.32562-1.46924l6.929-5.38477a1.12394,1.12394,0,0,1,1.47773.06983l.17675.17676A1.124,1.124,0,0,1,31.007,14.21729Zm-12.04584,7.7832c-3.2868.24365-2.93158,2.49756-4.27533,4.06787C13.80432,27.09814,12.875,27.24072,12.875,27.625s.35687.375.75.375h3c2.0451,0,3.49274-.2085,4.51-1.22607a2.14718,2.14718,0,0,0,.69562-1.9043ZM13.166,24.76855a3.19021,3.19021,0,0,1-.82618.65528A2.69856,2.69856,0,0,0,10.90839,27.276,11.52258,11.52258,0,0,1,8.0459,25.78613,12.00483,12.00483,0,0,1,20.18652,5.1748a4.50258,4.50258,0,0,1,1.22657,7.2334l-3.05371,3.06055a1.75348,1.75348,0,0,0,0,2.47656,1.80249,1.80249,0,0,0,.885.47168l-1.11444,1.67066a5.4037,5.4037,0,0,0-4.35064,3.58423A4.88286,4.88286,0,0,1,13.166,24.76855ZM14.29291,9.636a2,2,0,1,0,0-2.82843A2,2,0,0,0,14.29291,9.636ZM7.929,11.75739a2,2,0,1,0,0-2.82843A2.00009,2.00009,0,0,0,7.929,11.75739Zm.70709,6.364a2,2,0,1,0-2.82843,0A2,2,0,0,0,8.636,18.12134Zm2.82843,2.82843a2,2,0,1,0,0,2.82843A2,2,0,0,0,11.46448,20.94977Z"
			/>
		</svg>
	);
};

export const Bars = (props) => {
	return (
		<svg {...props} id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path
				id="bars"
				d="M9,24v3a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V24a1,1,0,0,1,1-1H8A1,1,0,0,1,9,24Zm5-4H12a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V21A1,1,0,0,0,14,20Zm6-6H18a1,1,0,0,0-1,1V27a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V15A1,1,0,0,0,20,14Zm6-9H24a1,1,0,0,0-1,1V27a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V6A1,1,0,0,0,26,5Z"
			/>
		</svg>
	);
};

export const CircleRemove = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="circle-remove"
				d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm3.182,9.76782a.49993.49993,0,0,1,0,.707l-.70716.707a.49988.49988,0,0,1-.70709,0L10,11.41418l-1.76776,1.7677a.49988.49988,0,0,1-.70709,0l-.70716-.707a.49993.49993,0,0,1,0-.707L8.58575,10,6.818,8.23218a.49993.49993,0,0,1,0-.707L7.52515,6.818a.50011.50011,0,0,1,.70709,0L10,8.58569,11.76776,6.818a.50011.50011,0,0,1,.70709,0l.70716.70716a.49993.49993,0,0,1,0,.707L11.41425,10Z"
			/>
		</svg>
	);
};

export const CirclePlus = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="circle-plus"
				d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm4,8.5a.5.5,0,0,1-.5.5H11v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11H6.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,6.5,9H9V6.5A.5.5,0,0,1,9.5,6h1a.5.5,0,0,1,.5.5V9h2.5a.5.5,0,0,1,.5.5Z"
			/>
		</svg>
	);
};

export const SquareDownload = (props) => {
	return (
		<svg {...props} id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
			<path
				id="square-download"
				d="M6.62341,7H9V1.5A.5.5,0,0,1,9.5,1h1a.5.5,0,0,1,.5.5V7h2.37659a.5.5,0,0,1,.40454.79382l-3.37659,4.649a.5.5,0,0,1-.80908,0l-3.37659-4.649A.5.5,0,0,1,6.62341,7ZM15,2H13V4h2a1.001,1.001,0,0,1,1,1V15a1.001,1.001,0,0,1-1,1H5a1.001,1.001,0,0,1-1-1V5A1.001,1.001,0,0,1,5,4H7V2H5A3.00328,3.00328,0,0,0,2,5V15a3.00328,3.00328,0,0,0,3,3H15a3.00328,3.00328,0,0,0,3-3V5A3.00328,3.00328,0,0,0,15,2Z"
			/>
		</svg>
	);
};
