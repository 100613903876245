import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DoomLogo } from "./doom.svg";
import "./style.scss";

/*
https://codepen.io/cbanlawi/pen/xxRBeMY
https://codepen.io/pgalor/pen/OeRWJQ
https://codepen.io/kkhenriquez/pen/PGvmjm
*/

export default function Doom() {
	return (
		<div className="doom">
			<DoomLogo />
		</div>
	);
}
