import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/navigation";
import FancyIcon from "../components/FancyIcon.js";
import FancyModal from "../components/FancyModal";
import "./default.scss";

export default function LayoutDefault(props) {
	return (
		<div className="LayoutDefault">
			<header>
				<Navigation />
			</header>
			<div className="MainWrapper">
				<main id="main" className="grid-container grid-container--home">
					{props.children}
				</main>
				<footer>
					{/* <div className="Columns">
						<div className="Links">
							Links
							<ul>
								<li>
									<Link to="/txt/privacy">Privacy Policy</Link>
								</li>
								<li>
									<Link to="/txt/tos">Terms of Service</Link>
								</li>
							</ul>
						</div>
						<div className="Signature">Signature</div>
					</div> */}
					<div className="SignOff">
						<span>
							Made with <FancyIcon icon={"GLYPHICON_BASIC_PIZZA"} /> for you, by me.
						</span>
						<span>
							<Link to="/txt/about">About</Link> / <Link to="/txt/tos">TOS</Link> /{" "}
							<Link to="/txt/privacy">Privacy</Link> / © 2022, Ben Hagen
						</span>
					</div>
				</footer>
			</div>
		</div>
	);
}

// export default function LayoutDefault(props) {
// 	return (
// 		<div className="LayoutDefault">
// 			<header>
// 				<Navigation />
// 			</header>
// 			<div className="MainWrapper">
// 				<main id="main" className="grid-container grid-container--home">
// 					{props.children}
// 				</main>
// 				<footer>
// 					<div className="Footer">
// 						<div className="Links">
// 							<ul>
// 								<li>
// 									<Link to="/txt/privacy">Privacy Policy</Link>
// 								</li>
// 								<li>
// 									<Link to="/txt/tos">Terms of Service</Link>
// 								</li>
// 							</ul>
// 							Links
// 						</div>
// 						<div className="Signature">Signature</div>
// 					</div>
// 				</footer>
// 			</div>
// 		</div>
// 	);
// }
