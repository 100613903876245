import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, useAuthDispatch } from "../../authentication";
import { Formik, Form, Field } from "formik";
// import { VaporParticles } from "../../Design/Vapor.js";
import * as Yup from "yup";
import "./style.scss";
import { ButtonFancy, InputFancy, CheckboxFancy } from "../../components/fancy";
import { LayoutBlank } from "../../layouts/blank";
import zxcvbn from "zxcvbn";

const SignInSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email"),
	password: Yup.string(),
});

export default function SignIn() {
	return (
		<LayoutBlank>
			<div className="SignIn">
				<SignInForm />
			</div>
		</LayoutBlank>
	);
}

export const SignInForm = () => {
	const dispatch = useAuthDispatch();
	const [view, setView] = useState("email");
	const [passwordPlaceholder, setPasswordPlaceholder] = useState("Password");
	let navigate = useNavigate();

	return (
		<div className="SignInForm BoxGlow">
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validationSchema={SignInSchema}
				onSubmit={async (values) => {
					let response = await loginUser(values.email, values.password, dispatch);
					if (response.status === 200) {
						navigate(-1, { replace: true });
					} else {
						setPasswordPlaceholder("Password");
						setView("error");
					}
				}}
			>
				{(formik) => (
					<Form>
						{view === "email" ? (
							<>
								<h1>Sign In</h1>
								<p>Enter your email address to begin the sign in process.</p>
								<Field
									key="email_input"
									type="text"
									focusOnMount={true}
									name="email"
									placeholder="Email Address"
									component={InputFancy}
									onKeyEnter={(e) => {
										if (!formik.errors["email"]) {
											setView("password");
										}
									}}
								/>
								{/* <Field
									key="stay"
									type="checkbox"
									name="check"
									placeholder="Huh"
									component={CheckboxFancy}
									// onKeyEnter={(e) => {
									// 	if (!formik.errors["email"]) {
									// 		setView("password");
									// 	}
									// }}
									label="TestTest"
								/> */}
								<ButtonFancy
									key="email_button"
									type="button"
									className={"ButtonFancy purple"}
									disabled={
										formik.errors["email"] || formik.values.email.length === 0 || formik.isSubmitting
											? true
											: false
									}
									onClick={() => {
										setView("password");
									}}
								>
									Continue
								</ButtonFancy>
								<div className={"or"}>or</div>
								<ButtonFancy type="button" className={"ButtonFancy transparent"}>
									Create a new account
								</ButtonFancy>
							</>
						) : view === "password" ? (
							<>
								<h1>Sign In</h1>
								<p>
									Welcome back, some more text. Goes here.{" "}
									<span
										onClick={() => {
											setView("email");
										}}
									>
										{formik.values.email}
									</span>
									!
								</p>
								<Field
									key="password_input"
									type="password"
									onChange={(event) => {
										let value = event.target.value;
										let result = zxcvbn(value);
										if (value === "") {
											setPasswordPlaceholder("Password");
										} else if (result.score === 0) {
											setPasswordPlaceholder("Password grade: F");
										} else if (result.score === 1) {
											setPasswordPlaceholder("Password grade: D");
										} else if (result.score === 2) {
											setPasswordPlaceholder("Password grade: C");
										} else if (result.score === 3) {
											setPasswordPlaceholder("Password grade: B");
										} else if (result.score === 4) {
											setPasswordPlaceholder("Password grade: A+");
										}
									}}
									focusOnMount={true}
									name="password"
									placeholder={passwordPlaceholder}
									component={InputFancy}
								/>
								<ButtonFancy
									key="password_button"
									type="submit"
									className={"ButtonFancy purple"}
									isSubmitting={formik.isSubmitting}
									disabled={
										formik.errors["password"] || formik.values.password.length === 0 || formik.isSubmitting
											? true
											: false
									}
								>
									Sign In
								</ButtonFancy>
								{/* <span className={"alternative"}>Need to create an account?</span> */}
							</>
						) : view === "success" ? (
							<>Success!</>
						) : view === "error" ? (
							<>
								<span className="errorTitle">Error://</span>
								<span className="errorMessage">
									There was an error signing in to your account. You probably provided the{" "}
									<strong>wrong email address or password</strong>.
								</span>
								<button
									type="button"
									className={"ButtonFancy transparent"}
									onClick={() => {
										console.log(formik.values);
										formik.resetForm({ values: { email: formik.values.email, password: "" } });
										setView("email");
									}}
								>
									Try Again!
								</button>
								<button type="button" className={"ButtonFancy transparent"}>
									Fuck it! Reset my password.
								</button>
							</>
						) : view === "reset" ? (
							<span
								onClick={() => {
									setView("email");
								}}
							>
								Reset password ain't here yet.
							</span>
						) : null}
					</Form>
				)}
			</Formik>
		</div>
	);
};
