import React, { useEffect, useState } from "react";
import { Settings } from "../../settings.js";
import LayoutDefault from "../../layouts/default.js";
import { Link } from "react-router-dom";
import { apiCall, userCan } from "../../authentication";
import { ButtonFancy } from "../../components/fancy";
import Spinner from "../../components/Spinner";
import "./style.scss";

export default function TXT(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [pages, setPages] = useState([]);
	document.title = Settings.TITLE_PREFIX + "TXT";

	async function loadList(path) {
		setIsLoading(true);
		let result = await apiCall("GET", `/v1/pages/`);
		setPages(result.data);
		setIsLoading(false);
	}

	async function downloadBackup() {
		let result = await apiCall("GET", `/v1/pages/export`);
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(result.data))}`;
		const link = document.createElement("a");
		link.href = jsonString;
		link.download = "data.json";
		link.click();
		link.remove();
	}

	useEffect(() => {
		loadList();
	}, []);

	return (
		<LayoutDefault>
			{isLoading ? (
				<Spinner></Spinner>
			) : (
				<div className="TXT">
					<ButtonFancy
						type="button"
						doubleConfirm={false}
						className={"ButtonFancy transparent"}
						onClick={downloadBackup}
					>
						Download Backup
					</ButtonFancy>

					<ul>
						{pages.map((path) => (
							<li key={path}>
								<Link to={"/txt/" + path}>{path}</Link>
							</li>
						))}
					</ul>
				</div>
			)}
		</LayoutDefault>
	);
}
