import React, { useState } from "react";
import "./navigation.scss";
import { Link } from "react-router-dom";
import { useAuthDispatch, logout, useAuthState } from "../authentication";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import FancyIcon from "./FancyIcon.js";

export default function Navigation(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
	let location = useLocation();
	let navigate = useNavigate();

	const handleLogout = () => {
		logout(dispatch);
		navigate("/");
	};

	const handleLogin = () => {
		//navigate({to: '/login', options: { state: { from: location }}});
		navigate("/login", { state: { from: location } });
	};

	return (
		<div className={classNames("Navigation", { HamburgerOpen: isHamburgerOpen })}>
			<nav>
				<div
					className="Hamburger"
					onClick={() => {
						setIsHamburgerOpen(!isHamburgerOpen);
					}}
				>
					{isHamburgerOpen ? (
						<FancyIcon icon={"GLYPHICON_HALFLING_CIRCLE_MENU_CLOSE"} />
					) : (
						<FancyIcon icon={"GLYPHICON_HALFLING_CIRCLE_MENU"} />
					)}
				</div>
				<div className="Logo">
					<Link to="/">HX.IO</Link>
				</div>
				<ul className="Menu" data-visible="false">
					<li>
						<Link to="/ip">IP</Link>
					</li>
					<li>
						<Link to="/txt">TXT</Link>
					</li>
					<li>
						{userDetails.user ? (
							<button onClick={handleLogout}>Sign Out</button>
						) : (
							<button onClick={handleLogin}>Sign In</button>
						)}
					</li>
				</ul>
			</nav>
		</div>
	);
}
