import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, useAuthDispatch } from "../../authentication";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ButtonFancy, InputFancy, CheckboxFancy } from "../../components/fancy";
import { LayoutBlank } from "../../layouts/blank";
import zxcvbn from "zxcvbn";
import {
	VaporLayoutCenter,
	VaporWindow,
	VaporWindowTitle,
	VaporWindowContent,
	VaporInput,
	VaporButton,
	VaporButtonList,
	VaporInlineButton,
	VaporWindowDivider,
	VaporParticles,
} from "../../Design/Vapor";
import "./style.scss";

const SignInSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email"),
	password: Yup.string(),
});

export default function SignInNew() {
	return (
		<div className={"SignInNew"}>
			<VaporLayoutCenter>
				<VaporParticles />
				<VaporWindow>
					<SignInForm />
				</VaporWindow>
			</VaporLayoutCenter>
		</div>
	);
}

export const SignInForm = () => {
	const dispatch = useAuthDispatch();
	const [view, setView] = useState("email");
	const [passwordPlaceholder, setPasswordPlaceholder] = useState("Password");
	let navigate = useNavigate();

	return (
		<div className="SignInFormNew">
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validationSchema={SignInSchema}
				onSubmit={async (values) => {
					let response = await loginUser(values.email, values.password, dispatch);
					if (response.status === 200) {
						navigate(-1, { replace: true });
					} else {
						setPasswordPlaceholder("Password");
						setView("error");
					}
				}}
			>
				{(formik) => (
					<Form>
						{view === "email" ? (
							<>
								<VaporWindowTitle icon={null}>Sign In</VaporWindowTitle>
								<VaporWindowContent>
									<p>Enter the email address associated with your account.</p>
									<Field
										key="email_input"
										type="text"
										focusOnMount={true}
										name="email"
										placeholder="Email Address"
										component={VaporInput}
										onKeyEnter={(e) => {
											if (!formik.errors["email"]) {
												setView("password");
											}
										}}
									/>
									<VaporButtonList direction="vertical">
										<VaporButton
											key="email_button"
											type="button"
											disabled={
												formik.errors["email"] ||
												formik.values.email.length === 0 ||
												formik.isSubmitting
													? true
													: false
											}
											onClick={() => {
												setView("password");
											}}
										>
											Continue
										</VaporButton>
										<VaporWindowDivider>Or</VaporWindowDivider>
										<VaporButton
											onClick={() => {
												setView("beta");
											}}
										>
											Create a New Account
										</VaporButton>
									</VaporButtonList>
								</VaporWindowContent>
							</>
						) : view === "password" ? (
							<>
								<VaporWindowTitle icon={null}>Sign In</VaporWindowTitle>
								<VaporWindowContent>
									<p>
										Enter the password associated with
										<VaporInlineButton
											onClick={() => {
												setView("email");
											}}
										>
											{formik.values.email}
										</VaporInlineButton>
										.
									</p>
									<Field
										key="password_input"
										type="password"
										onChange={(event) => {
											let value = event.target.value;
											let result = zxcvbn(value);
											if (value === "") {
												setPasswordPlaceholder("Password");
											} else if (result.score === 0) {
												setPasswordPlaceholder("Password grade: F");
											} else if (result.score === 1) {
												setPasswordPlaceholder("Password grade: D");
											} else if (result.score === 2) {
												setPasswordPlaceholder("Password grade: C");
											} else if (result.score === 3) {
												setPasswordPlaceholder("Password grade: B");
											} else if (result.score === 4) {
												setPasswordPlaceholder("Password grade: A+");
											}
										}}
										focusOnMount={true}
										onKeyEnter={() => {
											console.log("OK");
											formik.submitForm();
										}}
										name="password"
										placeholder={passwordPlaceholder}
										component={VaporInput}
									/>
									<VaporButtonList direction="vertical">
										<VaporButton
											key="password_button"
											type="submit"
											isSubmitting={formik.isSubmitting}
											disabled={
												formik.errors["password"] ||
												formik.values.password.length === 0 ||
												formik.isSubmitting
													? true
													: false
											}
										>
											Sign In
										</VaporButton>
										<VaporButton
											type="button"
											isTransparent={true}
											onClick={() => {
												setView("beta");
											}}
										>
											Forgot password?
										</VaporButton>
									</VaporButtonList>
								</VaporWindowContent>
							</>
						) : view === "success" ? (
							<>Success!</>
						) : view === "error" ? (
							<>
								<VaporWindowTitle>Error</VaporWindowTitle>
								<VaporWindowContent>
									<p>
										There was an error signing in to your account. You probably provided the{" "}
										<strong>wrong email address or password</strong>.
									</p>
									<VaporButton
										type="button"
										onClick={() => {
											console.log(formik.values);
											formik.resetForm({ values: { email: formik.values.email, password: "" } });
											setView("email");
										}}
									>
										Try Again!
									</VaporButton>
									<VaporButton
										type="button"
										isTransparent={true}
										onClick={() => {
											setView("beta");
										}}
									>
										Fuck it! Reset my password.
									</VaporButton>
								</VaporWindowContent>
							</>
						) : view === "beta" ? (
							<>
								<VaporWindowTitle>Too Soon</VaporWindowTitle>
								<VaporWindowContent>
									<p>This functionality doesn't exist yet. Whoops!</p>
									<VaporButton
										onClick={() => {
											setView("email");
										}}
									>
										Sigh ...
									</VaporButton>
								</VaporWindowContent>
							</>
						) : null}
					</Form>
				)}
			</Formik>
		</div>
	);
};
