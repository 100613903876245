import React, { useRef, useState } from "react";
import "./App.scss";
import { AuthProvider } from "./authentication";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import { useAuthState } from "./authentication";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";

import SignIn from "./Pages/SignIn";
import SignInNew from "./Pages/SignInNew";
import IpInformation from "./Pages/IpInformation";
import Color from "./Pages/Color";
import Doom from "./Pages/Doom";
import Root from "./Pages/Root";
import TXT from "./Pages/TXT";
import Dashboard from "./Pages/Dashboard";
import NotFound from "./Pages/NotFound";
import MarkdownPage from "./Pages/MarkdownPageVapor";
import Editor from "./Pages/MarkdownPageVapor/Editor";
import { VaporParticles } from "./Design/Vapor";

// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
// https://github.com/nero2009/login-auth-useContext/blob/master/src/Pages/Dashboard/index.js

function RequireAuth(props) {
	const userDetails = useAuthState();
	let location = useLocation();

	if (!userDetails.user || !userDetails.user.email) {
		console.log("You must be signed in to view this route; Redirecting to '/login'");
		return <Navigate to="/signin" state={{ from: location }} />;
	}

	return <>{props.children}</>;
}

function App() {
	const [showGrid, setShowGrid] = useState(false);
	const app = useRef(null);

	useHotkeys("command+]", () => {
		setShowGrid((showGrid) => !showGrid);
	});

	return (
		<AuthProvider>
			<BrowserRouter>
				<div className={classNames("App", { grid: showGrid })} ref={app}>
					{/* <VaporParticles /> */}
					<Routes>
						<Route path="/" element={<Navigate replace to="/txt/root" />} />
						<Route path="/color" element={<Color />} />
						<Route path="/doom" element={<Doom />} />
						<Route path="/ip" element={<IpInformation />} />
						<Route path="/signin" element={<SignInNew />} />
						<Route path="/txt" element={<TXT />} />
						<Route
							path="/dashboard"
							element={
								<RequireAuth>
									<Dashboard />
								</RequireAuth>
							}
						/>
						<Route
							exact
							path="/txt/*"
							element={
								<RequireAuth>
									<PageViewRouting />
								</RequireAuth>
							}
						/>
						<Route
							path="*"
							element={
								<RequireAuth>
									<NotFound />
								</RequireAuth>
							}
						/>
					</Routes>
				</div>
			</BrowserRouter>
		</AuthProvider>
	);
}
export default App;

// Special routing to accomodate the end ! views
function PageViewRouting() {
	let urlParams = useParams();
	const [paramPath, paramView] = urlParams["*"].split("!");
	if (paramView) {
		return <Editor />;
	}
	return <MarkdownPage />;
}
