import { EditorView } from "@codemirror/view";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { classHighlighter, tags as t } from "@lezer/highlight";
import vapors from "../../Design/Styles/Variables.scss";

// Using https://github.com/one-dark/vscode-one-dark-theme/ as reference for the colors

// const chalky = "#e5c07b",
// 	coral = "#e06c75",
// 	cyan = "#56b6c2",
// 	invalid = "#ffffff",
// 	ivory = "#abb2bf",
// 	stone = "#7d8799", // Brightened compared to original to increase contrast
// 	malibu = "#61afef",
// 	sage = "#98c379",
// 	whiskey = "#d19a66",
// 	violet = "#c678dd",
// 	darkBackground = "#21252b",
// 	highlightBackground = "#2c313a",
// 	background = "#282c34",
// 	tooltipBackground = "#353a42",
// 	selection = "#3E4451",
// 	cursor = "#528bff";

// console.log(vapors);

/// The editor theme styles for One Dark.
export const vaporTheme = EditorView.theme(
	{
		"&": {
			color: vapors.color_text_400,
			// backgroundColor: "transparent !important",
			//backgroundColor: vapors.color_background_400,
			background: "transparent",
			height: "100%",
		},

		".CodeMirror": {
			fontFamily: vapors.font_monospace + " !important",
		},

		".cm-content": {
			caretColor: vapors.color_text_400,
		},

		".cm-cursor, .cm-dropCursor": { borderLeftColor: vapors.color_text_400 },
		"&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection": {
			//backgroundColor: selection,
		},

		// ".cm-panels": { backgroundColor: darkBackground, color: ivory },
		".cm-panels.cm-panels-top": { borderBottom: "2px solid black" },
		".cm-panels.cm-panels-bottom": { borderTop: "2px solid black" },

		".cm-searchMatch": {
			backgroundColor: "#72a1ff59",
			outline: "1px solid #457dff",
		},
		".cm-searchMatch.cm-searchMatch-selected": {
			backgroundColor: "#6199ff2f",
		},

		".cm-line": {
			fontFamily: vapors.font_monospace,
		},

		".cm-activeLine": {
			backgroundColor: "rgba(255,255,255,.05)",
		},
		".cm-selectionMatch": { backgroundColor: "#aafe661a" },

		"&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket": {
			backgroundColor: "#bad0f847",
			outline: "1px solid #515a6b",
		},

		".cm-gutters": {
			fontFamily: vapors.font_monospace,
			fontSize: ".8rem",
			//backgroundColor: background,
			backgroundColor: vapors.color_background_400,
			textAlign: "center",
			border: "none",
			color: vapors.color_background_700,
		},

		".cm-gutterElement": {
			color: vapors.color_background_700,
			display: "flex",
			verticalAlign: "middle",
			alignItems: "center",
			justifyContent: "right",
		},

		".cm-activeLineGutter": {
			//backgroundColor: highlightBackground,
			backgroundColor: vapors.color_background_400,
			color: vapors.color_text_400,
		},

		".cm-foldPlaceholder": {
			backgroundColor: "transparent",
			border: "none",
			color: "#ddd",
		},

		".cm-tooltip": {
			border: "none",
			// backgroundColor: tooltipBackground,
		},
		".cm-tooltip .cm-tooltip-arrow:before": {
			borderTopColor: "transparent",
			borderBottomColor: "transparent",
		},
		".cm-tooltip .cm-tooltip-arrow:after": {
			// borderTopColor: tooltipBackground,
			// borderBottomColor: tooltipBackground,
		},
		".cm-tooltip-autocomplete": {
			"& > ul > li[aria-selected]": {
				// backgroundColor: highlightBackground,
				// color: ivory,
			},
		},
	},
	{ dark: true }
);

/// The highlighting style for code in the One Dark theme.
export const vaporHighlightStyle = HighlightStyle.define([
	{ tag: t.keyword, color: vapors.color_blue_400 },
	{ tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName], color: vapors.color_red_400 },
	{ tag: [t.function(t.variableName), t.labelName], color: vapors.color_yellow_400 },
	{ tag: [t.color, t.constant(t.name), t.standard(t.name)], color: vapors.color_orange_400 },
	{ tag: [t.definition(t.name), t.separator], color: vapors.color_red_400 },
	{
		tag: [t.typeName, t.className, t.number, t.changed, t.annotation, t.modifier, t.self, t.namespace],
		color: vapors.color_green_400,
	},
	{
		tag: [t.operator, t.operatorKeyword, t.url, t.escape, t.regexp, t.link, t.special(t.string)],
		color: vapors.color_blue_400,
	},
	{ tag: [t.meta, t.comment], color: vapors.color_text_100 },
	{ tag: t.strong, fontWeight: "bold", color: vapors.color_blue_400 },
	{ tag: t.emphasis, fontStyle: "italic", color: vapors.color_blue_300 },
	{ tag: t.strikethrough, textDecoration: "line-through" },

	{ tag: t.link, color: vapors.color_yellow_400 },
	{
		tag: t.url,
		fontStyle: "italic",
		textDecoration: "underline",
		color: vapors.color_green_400,
	},

	{ tag: [t.heading, t.heading1, t.heading2, t.heading3], fontWeight: "bold", color: vapors.color_pink_400 },
	{ tag: [t.atom, t.bool, t.special(t.variableName)], color: vapors.color_orange_400 },
	{ tag: [t.processingInstruction, t.string, t.inserted], color: vapors.color_orange_400 },
	// { tag: t.invalid, color: invalid },
	{ tag: t.quote, fontStyle: "italic", color: vapors.color_green_400 },
	{
		tag: t.heading1,
		fontSize: "1.6em",
		fontWeight: "bold",
		color: vapors.color_pink_400,
	},
	{
		tag: t.heading2,
		fontSize: "1.4em",
		fontWeight: "bold",
		color: vapors.color_pink_400,
	},
	{
		tag: t.heading3,
		fontSize: "1.2em",
		fontWeight: "bold",
		color: vapors.color_pink_400,
	},
]);

/// Extension to enable the One Dark theme (both the editor theme and
/// the highlight style).
export const vapor = [vaporTheme, syntaxHighlighting(classHighlighter), syntaxHighlighting(vaporHighlightStyle)];
