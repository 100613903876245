import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./fancy.scss";

export function ButtonFancy(props) {
	//const [isActive, setIsActive] = useState(false);
	const [isDoubleConfirmed, setIsDoubleConfirmed] = useState(false);
	const [timeoutConfirm, setTimeoutConfirm] = useState(null);
	const [content, setContent] = useState(props.children);

	useEffect(() => {
		return function cleanup() {
			clearTimeout(timeoutConfirm);
		};
	}, [timeoutConfirm]);

	function handleOnClick(e) {
		//e.preventDefault();
		if (props.doubleConfirm && !isDoubleConfirmed) {
			setIsDoubleConfirmed(true);
			setContent("Confirm?");
			setTimeoutConfirm(
				setTimeout(() => {
					setIsDoubleConfirmed(false);
					setContent(props.children);
				}, 5000)
			);
			// timeoutConfirm: setTimeout(() => {
			// 	setIsDoubleConfirmed(false);
			// 	setContent(props.children);
			// }, 5000);
		} else {
			if (props.onClick) {
				props.onClick(e);
			}
		}
	}

	return (
		<button
			type={props.type}
			className={classNames(
				"ButtonFancy",
				{ [props.className]: !isDoubleConfirmed },
				{ [props.doubleConfirmClass]: isDoubleConfirmed }
			)}
			disabled={props.disabled}
			onClick={handleOnClick}
		>
			{!props.isSubmitting ? (
				<>{content}</>
			) : (
				<div className="circle">
					<svg className="circle__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<circle className="circle__svg-circle" cx="50" cy="50" r="45" />
					</svg>
				</div>
			)}
		</button>
	);
}

ButtonFancy.propTypes = {
	onClick: PropTypes.func,
	doubleConfirm: PropTypes.bool,
	doubleConfirmClass: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
ButtonFancy.defaultProps = {
	disabled: false,
	type: "submit",
	active: false,
	doubleConfirmClass: "red",
};

export function InputFancy({ field, form: { touched, errors }, ...props }) {
	const [isActive, setIsActive] = useState(false);
	const input = useRef(null);

	// Copy props to pass into the actual input element and remove the custom stuff
	const inputProps = Object.assign({}, props);
	delete inputProps.onKeyEnter;
	delete inputProps.onKeyEscape;
	delete inputProps.focusOnMount;
	delete inputProps.onChange;
	delete inputProps.defaultPlaceholder;
	delete inputProps.overridePlaceholder;

	function onBlur(e) {
		setIsActive(false);
		field.onBlur(e);
	}

	function onFocus(e) {
		setIsActive(true);
	}

	function handleKeyPress(event) {
		if (event.key === "Enter" && props.onKeyEnter) {
			props.onKeyEnter(event);
			event.preventDefault();
		}
		if (event.key === "Escape" && props.onKeyEscape) {
			props.onKeyEscape(event);
			event.preventDefault();
		}
	}

	function onChange(e) {
		if (props.onChange) {
			props.onChange(e);
		}
		field.onChange(e);
	}

	useEffect(() => {
		if (props.focusOnMount) {
			input.current.focus();
		}
	}, [props.focusOnMount]);

	let placeholder = props.defaultPlaceholder ? props.defaultPlaceholder : props.placeholder;
	if (errors[field.name]) placeholder = errors[field.name];
	if (props.overridePlaceholder) {
		console.log("changed");
		placeholder = props.overridePlaceholder;
	}
	//console.log(props.overridePlaceholder);

	useEffect(() => {
		console.log("OK");
		placeholder = props.overridePlaceholder;
	}, [props.overridePlaceholder]);

	return (
		<div
			className={classNames({
				InputFancy: true,
				Active: isActive,
				Invalid: errors[field.name],
				Touched: touched[field.name],
				Filled: field.value && field.value.length > 0,
			})}
		>
			<label
				onClick={(event) => {
					input.current.focus();
				}}
			>
				{placeholder}
			</label>
			<input
				ref={input}
				{...field}
				{...inputProps}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyPress={(e) => {
					handleKeyPress(e);
				}}
				onKeyDown={(e) => {
					handleKeyPress(e);
				}}
			/>
		</div>
	);
}
InputFancy.propTypes = {
	focusOnMount: PropTypes.bool,
	onKeyEnter: PropTypes.func,
	onChange: PropTypes.func,
};

export function CheckboxFancy({ type, label, field, form: { touched, errors }, ...props }) {
	const [isActive, setIsActive] = useState(false);
	const inputProps = Object.assign({}, props);

	return (
		<div
			className={classNames({
				CheckboxFancy: true,
				Active: isActive,
				Invalid: errors[field.name],
				Touched: touched[field.name],
				Filled: field.value && field.value.length > 0,
			})}
		>
			<span className={"checkbox"}>
				<label>
					<input type={type} {...inputProps} />
					<div>
						<span></span>
					</div>
				</label>
			</span>
			<div className={"label"}>{label}</div>
		</div>
	);
}

CheckboxFancy.defaultProps = {
	type: "checkbox",
	form: { touched: false, errors: {} },
	field: { name: "default_name" },
	label: null,
};

// export class Checkbox extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			is_active: false,
// 		};
// 	}

// 	handleFocus = (e) => {
// 		this.setState({ is_active: true });
// 	};

// 	handleBlur = (e) => {
// 		const {
// 			field: { onBlur },
// 		} = this.props;
// 		this.setState({ is_active: false });
// 		onBlur(e);
// 	};

// 	render() {
// 		const { is_active } = this.state;

// 		return (
// 			<div
// 				className={classNames("form_checkbox", {
// 					active: is_active,
// 					invalid: this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name],
// 				})}
// 			>
// 				<div className="wrapper">
// 					<label>
// 						<input
// 							type="checkbox"
// 							{...this.props.field}
// 							{...this.props}
// 							onFocus={this.handleFocus}
// 							onBlur={this.handleBlur}
// 						/>
// 						<div>
// 							<span></span>
// 							{this.props.label}
// 						</div>
// 					</label>
// 				</div>
// 				{this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name] && (
// 					<div className="error">{this.props.form.errors[this.props.field.name]}</div>
// 				)}
// 			</div>
// 		);
// 	}
// }

// import React from "react";
// import classNames from "classnames";
// import PropTypes from "prop-types";
// import "./button_fancy.scss";

// export default class ButtonFancy extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.onClick = this.onClick.bind(this);
// 		this.state = {
// 			button_text: this.props.text,
// 			isDoubleConfirmed: false,
// 		};
// 	}

// 	componentWillUnmount() {
// 		// Cleanup timers to prevent memory leaks
// 		clearTimeout(this.state.timeoutConfirm);
// 	}

// 	onClick() {
// 		if (this.props.doubleConfirm && !this.state.isDoubleConfirmed) {
// 			console.log("FancyButton doubleConfirm");
// 			this.setState({
// 				isDoubleConfirmed: true,
// 				button_text: this.props.doubleConfirm,
// 				timeoutConfirm: setTimeout(
// 					this.setState.bind(this, { isDoubleConfirmed: false, button_text: this.props.text }),
// 					5000
// 				),
// 			});
// 		} else {
// 			console.log("Activating FancyButton passed onClick");
// 			this.props.onClick();
// 		}
// 	}

// 	render() {
// 		let buttonClasses = {};
// 		if (this.props.classNames) {
// 			buttonClasses = this.props.classNames;
// 		} else {
// 			buttonClasses = {};
// 		}
// 		buttonClasses["ButtonFancy"] = true;
// 		buttonClasses[this.props.classNames] = true;

// 		var content = null;

// 		if (this.props.children) {
// 			content = this.props.children;
// 		} else {
// 			if (this.state.isDoubleConfirmed) {
// 				content = this.props.doubleConfirm;
// 			} else {
// 				content = this.state.button_text;
// 			}
// 		}

// 		buttonClasses[this.props.doubleConfirmClass] = this.state.isDoubleConfirmed;
// 		var props = {
// 			className: classNames(buttonClasses),
// 			disabled: this.props.disabled,
// 			type: this.props.type,
// 		};

// 		if (this.props.onClick) {
// 			props.onClick = this.onClick;
// 		}

// 		if (this.props.active) {
// 			if (this.props.active === true) {
// 				props.disabled = true;
// 				content = (
// 					<div>
// 						{content}
// 						<div className="circle">
// 							<svg className="circle__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
// 								<circle className="circle__svg-circle" cx="50" cy="50" r="45" />
// 							</svg>
// 						</div>
// 					</div>
// 				);
// 			} else {
// 				props.disabled = true;
// 				content = <div>{this.props.active}</div>;
// 			}
// 		}
// 		return <button {...props}>{content}</button>;
// 	}
// }

// ButtonFancy.propTypes = {
// 	text: PropTypes.string,
// 	classNames: PropTypes.object,
// 	onClick: PropTypes.func,
// 	doubleConfirm: PropTypes.string,
// 	doubleConfirmClass: PropTypes.string,
// 	disabled: PropTypes.bool,
// 	type: PropTypes.string,
// 	active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
// };

// ButtonFancy.defaultProps = {
// 	text: "button",
// 	disabled: false,
// 	type: "submit",
// 	active: false,
// };
