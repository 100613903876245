import React from "react";
import { useNavigate } from "react-router-dom";

import FancyIcon from "../../components/FancyIcon";
import { ButtonFancy } from "../../components/fancy";
import { LayoutBlank } from "../../layouts/blank.js";
import classNames from "classnames";
import "./style.scss";
import PropTypes from "prop-types";
import {
	VaporLayoutCenter,
	VaporButtonList,
	VaporWindow,
	VaporWindowTitle,
	VaporWindowContent,
	VaporButton,
} from "../../Design/Vapor";

function NotFound(props) {
	let navigate = useNavigate();
	return (
		<VaporLayoutCenter>
			<VaporWindow>
				<VaporWindowTitle>Error:404</VaporWindowTitle>
				<VaporWindowContent>
					<p>
						404 Not Found: The URL you requested does not exist. No cause for alarm. No cause for anything really.
					</p>
					<blockquote cite="https://www.brainyquote.com/quotes/friedrich_nietzsche_138561">
						We should consider every day lost on which we have not danced at least once. And we should call every
						truth false which was not accompanied by at least one laugh.
						<span className="author">Friedrich Nietzsche</span>
					</blockquote>
					<VaporButtonList direction="vertical">
						<VaporButton
							onClick={() => {
								navigate(`/`);
							}}
						>
							Go Home
						</VaporButton>
					</VaporButtonList>
				</VaporWindowContent>
			</VaporWindow>
		</VaporLayoutCenter>
	);
}

export default NotFound;

function NotFoundOld(props) {
	return (
		<LayoutBlank>
			<div className={"NotFound"}>
				<div className={"Hero glitch"} data-text="404-NF">
					<span>404-NF</span>
				</div>
				<div className={"Children"}>{props.children}</div>
			</div>
		</LayoutBlank>
	);
}
