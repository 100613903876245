import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export function VaporInput({ field, form: { touched, errors }, ...props }) {
	const [isActive, setIsActive] = useState(false);
	const input = useRef(null);

	// Copy props to pass into the actual input element and remove the custom stuff
	const inputProps = Object.assign({}, props);
	delete inputProps.onKeyEnter;
	delete inputProps.onKeyEscape;
	delete inputProps.focusOnMount;
	delete inputProps.onChange;
	delete inputProps.defaultPlaceholder;
	delete inputProps.overridePlaceholder;

	function onBlur(e) {
		setIsActive(false);
		field.onBlur(e);
	}

	function onFocus(e) {
		setIsActive(true);
	}

	function handleKeyPress(event) {
		if (event.key === "Enter" && props.onKeyEnter) {
			props.onKeyEnter(event);
			event.preventDefault();
		}
		if (event.key === "Escape" && props.onKeyEscape) {
			props.onKeyEscape(event);
			event.preventDefault();
		}
	}

	function onChange(e) {
		if (props.onChange) {
			props.onChange(e);
		}
		field.onChange(e);
	}

	useEffect(() => {
		if (props.focusOnMount) {
			input.current.focus();
		}
	}, [props.focusOnMount]);

	let placeholder = props.defaultPlaceholder ? props.defaultPlaceholder : props.placeholder;
	if (errors[field.name]) placeholder = errors[field.name];
	if (props.overridePlaceholder) {
		console.log("changed");
		placeholder = props.overridePlaceholder;
	}
	//console.log(props.overridePlaceholder);

	useEffect(() => {
		console.log("OK");
		placeholder = props.overridePlaceholder;
	}, [props.overridePlaceholder]);

	return (
		<div
			className={classNames({
				vapor_input: true,
				Active: isActive,
				Invalid: errors[field.name],
				Touched: touched[field.name],
				Filled: field.value && field.value.length > 0,
			})}
		>
			<label
				onClick={(event) => {
					input.current.focus();
				}}
			>
				{placeholder}
			</label>
			<input
				ref={input}
				{...field}
				{...inputProps}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyPress={(e) => {
					handleKeyPress(e);
				}}
				onKeyDown={(e) => {
					handleKeyPress(e);
				}}
			/>
		</div>
	);
}
VaporInput.propTypes = {
	focusOnMount: PropTypes.bool,
	onKeyEnter: PropTypes.func,
	onChange: PropTypes.func,
};
