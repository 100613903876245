import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import FancyIcon from "../components/FancyIcon";
import "./Window.scss";

export function VaporWindow(props) {
	return (
		<div className={"vapor_window"}>
			<div className={"window"}>{props.children}</div>
			{props.footer ? <div className={"footer"}>{props.footer}</div> : <></>}
		</div>
	);
}
VaporWindow.propTypes = {
	title: PropTypes.any,
	titleIcon: PropTypes.any,
	content: PropTypes.any,
	footer: PropTypes.any,
};
VaporWindow.defaultProps = {
	titleIcon: <FancyIcon icon={"ROUNDED_TRIANGLE"} />,
	footer: (
		<>
			<div>
				<Link to="/">HX.IO</Link>&nbsp;
				<Link to="/ip">
					<FancyIcon icon="GLYPHICON_HALFLING_GLOBE" />
				</Link>
				&nbsp;//&nbsp;
				{Math.floor(Date.now() / 1000)}
			</div>
			<div></div>
			<div>For authorized use only</div>
		</>
	),
};

export function VaporWindowTitle(props) {
	return (
		<div className={"vapor_window_title"}>
			<h1>
				{props.icon}
				{props.children}
			</h1>
		</div>
	);
}
VaporWindowTitle.propTypes = {
	icon: PropTypes.any,
};
VaporWindowTitle.defaultProps = {
	icon: <FancyIcon icon={"ROUNDED_TRIANGLE"} />,
};

export function VaporWindowContent(props) {
	return <div className={"vapor_window_content"}>{props.children}</div>;
}

function VaporBase(props) {
	function classes() {
		return ["test1", "test2"];
	}
}
VaporBase.defaultProps = {
	marginTop: 100,
	marginBottom: 100,
};

export function VaporWindowDivider(props) {
	if (props.children) {
		return <div className={"vapor_window_divider children"}>{props.children}</div>;
	} else {
		return <div className={"vapor_window_divider no_children"}>{props.children}</div>;
	}
}
