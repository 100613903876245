import { createMarkupEditorShapeStyleControls } from "pintura";
import React, { useState, useEffect, useRef } from "react";
// https://github.com/bgrins/TinyColor
import tinycolor from "tinycolor2";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import chroma from "chroma-js";
import * as Icons from "./icons";
import "./style.scss";
import { array } from "yup";

// https://colorbrewer2.org/#
// #00e5e6
// hsv(185, 100%, 100%)
// 180 101 90

let brewer = ["#e41a1c", "#377eb8", "#4daf4a", "#984ea3", "#ff7f00", "#ffff33"];

let custom = ["#30D5C8", "#39054D", "#DB004C", "#F56E00", "#FFCA01"];

// [{"color":"#14161a","name":"background"},{"color":"#ff6666","name":"red"},{"color":"#ff9933","name":"orange"},{"color":"#fefe66","name":"yellow"},{"color":"#66ff66","name":"green"},{"color":"#66ffff","name":"blue"},{"color":"#e666ff","name":"purple"}]
// [{"color":"#14141a","name":"background"},{"color":"#ff3333","name":"red"},{"color":"#ff9933","name":"orange"},{"color":"#fefe33","name":"yellow"},{"color":"#33ff33","name":"green"},{"color":"#33ffff","name":"blue"},{"color":"#dd33ff","name":"purple"}]
// [{"color":"#14141a","name":"background"},{"color":"#ff3333","name":"red"},{"color":"#ff9933","name":"orange"},{"color":"#fefe33","name":"yellow"},{"color":"#1afc65","name":"green"},{"color":"#33ffff","name":"blue"},{"color":"#dd33ff","name":"purple"},{"color":"#e6ffff","name":"text"}]
// [{"color":"#14141a","name":"background"},{"color":"#ff3333","name":"red"},{"color":"#ff9933","name":"orange"},{"color":"#fefe33","name":"yellow"},{"color":"#1afc65","name":"green"},{"color":"#33ffff","name":"blue"},{"color":"#dd33ff","name":"purple"},{"color":"#ff19b3","name":"pink"},{"color":"#e6ffff","name":"text"}]

let vaporWave = [
	"#000",
	"#fff",
	"#f36",
	"#141414",
	"#40C4FF",
	"#47ffa0",
	"#1B1E23",
	"#0A0A0A",
	"#ffffff22",
	"#ffffff99",
	"#00809B33",
	"#00000070",
	"#f8f8f0",
	"#FF5252",
	"#FFAB40",
	"#ffffff66",
	"#ff000000",
	"#40C4FF00",
	"#47ffa000",
	"#E040FB00",
	"#FFFF0000",
	"#ffffff10",
	"#ffffff05",
	"#0026ff69",
	"#ffffff30",
	"#ffffff80",
	"#ffffff20",
	"#40c3ff66",
	"#0c0c0c",
	"#181A1F",
	"#ffffff33",
	"#1E1E1E",
	"#2c313a",
	"#00000090",
	"#ffffff",
	"#ffffff00",
	"#00000000",
	"#40c3ff00",
	"#848bbd",
	"#ff8b39",
	"#b6b1b1",
	"#f73232",
	"#fe4450",
	"#28b881",
	"#fede5d",
	"#f97e72",
	"#1afc65",
	"#36f9f6",
	"#72f1b8",
	"#D50",
	"#2EE2FA",
	"#f735c0",
	"#fc98df",
	"#ff7edb",
	"#2ee2fa",
	"#ffffffee",
	"#b6b1b1cc",
	"#ff7edbff",
];

function sortByHue(input) {
	input.sort((a, b) => {
		let hue_a = tinycolor(a).toHsv()["h"];
		let hue_b = tinycolor(b).toHsv()["h"];
		if (hue_a > hue_b) {
			return 1;
		}
		if (hue_a < hue_b) {
			return -1;
		}
		return 0;
	});
	return input;
}

sortByHue(vaporWave);

let cleanVaporWave = [];
vaporWave.map((clr, i) => {
	clr = tinycolor(clr);
	let v = clr.toHsv().v * 100;
	let s = clr.toHsv().s * 100;
	if (v > 10 && s > 10 && !cleanVaporWave.includes(clr.toHexString())) {
		cleanVaporWave.push(clr.toHexString());
	}
});

function hslGradient(color, steps) {
	const clrHsl = tinycolor(color).toHsl();
	let gradient = [];
	for (var i = 0; i < steps; i++) {
		gradient.push(tinycolor({ h: clrHsl.h, s: clrHsl.s, l: (100 / steps) * i }).toHexString());
	}
	return gradient;
}

function hsvGradient(color, steps) {
	const clrHsl = tinycolor(color).toHsl();
	let gradient = [];
	for (var i = 0; i < steps; i++) {
		gradient.push(tinycolor({ h: clrHsl.h, s: clrHsl.s, v: (100 / steps) * i }).toHexString());
	}
	return gradient;
}

function monoHSVGradient(color) {
	const clrHsv = color.toHsv();
	console.log(clrHsv);
	let gradient = [];
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.min(clrHsv.s + 0.2, 1), v: Math.max(clrHsv.v - 0.25, 0) }).toHexString()); // 100
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.min(clrHsv.s + 0.15, 1), v: Math.max(clrHsv.v - 0.175, 0) }).toHexString()); // 200
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.min(clrHsv.s + 0.1, 1), v: Math.max(clrHsv.v - 0.1, 0) }).toHexString()); // 300
	gradient.push(tinycolor({ h: clrHsv.h, s: clrHsv.s, v: clrHsv.v }).toHexString()); // 400
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.max(clrHsv.s - 0.05, 0), v: Math.min(clrHsv.v + 0.05, 1) }).toHexString()); // 500
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.max(clrHsv.s - 0.1, 0), v: Math.min(clrHsv.v + 0.125, 1) }).toHexString()); // 600
	gradient.push(tinycolor({ h: clrHsv.h, s: Math.max(clrHsv.s - 0.15, 0), v: Math.min(clrHsv.v + 0.175, 1) }).toHexString()); // 700
	return gradient;
}

function monoHSLGradient(color) {
	// 100, 200, 300, 400, 500, 600, 700
	const clrHsl = color.toHsl();
	console.log(clrHsl);
	let gradient = [];
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.min(clrHsl.s + 0.2, 1), l: Math.max(clrHsl.l - 0.25, 0) }).toHexString()); // 100
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.min(clrHsl.s + 0.15, 1), l: Math.max(clrHsl.l - 0.175, 0) }).toHexString()); // 200
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.min(clrHsl.s + 0.1, 1), l: Math.max(clrHsl.l - 0.1, 0) }).toHexString()); // 300
	gradient.push(tinycolor({ h: clrHsl.h, s: clrHsl.s, l: clrHsl.l }).toHexString()); // 400
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.max(clrHsl.s - 0.1, 0), l: Math.min(clrHsl.l + 0.1, 1) }).toHexString()); // 500
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.max(clrHsl.s - 0.15, 0), l: Math.min(clrHsl.l + 0.175, 1) }).toHexString()); // 600
	gradient.push(tinycolor({ h: clrHsl.h, s: Math.max(clrHsl.s - 0.2, 0), l: Math.min(clrHsl.l + 0.25, 1) }).toHexString()); // 700
	return gradient;
}

function monoGradient(color) {
	if (color.toHsv().s < 0.25) {
		return monoHSVGradient(color);
	} else {
		return monoHSLGradient(color);
	}
}

function progression(color, steps) {
	let clr = tinycolor(color);
	let output = [tinycolor(color).toHexString()];
	for (var i = 0; i < steps - 1; i++) {
		output.push(clr.spin(360 / steps).toHexString());
	}
	return output;
}

function ColorRow(props) {
	return (
		<ul className={classNames("color_row", props.className)}>
			{props.colors.map((color, index) => (
				<li
					key={props.className + color + index}
					onClick={() => {
						props.onClick(tinycolor(color), "");
					}}
					style={{ backgroundColor: color }}
				>
					&nbsp;
				</li>
			))}
		</ul>
	);
}

function ColorSwatch(props) {
	const hexInput = useRef();
	const [isPaletteVisible, setIsPaletteVisible] = useState(false);
	const [isGradientVisible, setIsGradientVisible] = useState(false);
	const style = {
		backgroundColor: props.color.toHexString(),
	};
	const highlightColor = props.color.isDark() ? "#FFFFFF" : "#000000";

	useEffect(() => {
		hexInput.current.value = props.color.toHexString();
	}, [props.color]);

	return (
		<div className={"color_swatch"} style={style}>
			<div
				className={"color"}
				onClick={() => {
					// props.setColor(color);
				}}
			>
				<Icons.CircleRemove
					className="remove"
					style={{ fill: highlightColor }}
					onClick={(e) => {
						props.onRemove(props.id);
						e.stopPropagation();
					}}
				/>
				{props.isStarred ? (
					<Icons.Star
						className="star"
						style={{ fill: highlightColor }}
						onClick={(e) => {
							// setIsStarred(false);
							// props.onUnStar(props.color.toHexString());
							e.stopPropagation();
						}}
					/>
				) : (
					<Icons.StarEmpty
						className="star"
						style={{ fill: highlightColor }}
						onClick={(e) => {
							// setIsStarred(true);
							props.onRemove(props.id);
							// props.onStar(props.color.toHexString());
							e.stopPropagation();
						}}
					/>
				)}
				<Icons.Palette
					className="palette"
					style={{ fill: highlightColor }}
					onClick={(e) => {
						setIsPaletteVisible(!isPaletteVisible);
						e.stopPropagation();
					}}
				/>
				<Icons.Bars
					className="bars"
					style={{ fill: highlightColor }}
					onClick={(e) => {
						setIsGradientVisible(!isGradientVisible);
						e.stopPropagation();
					}}
				/>
				<input
					type="text"
					style={{ color: highlightColor }}
					onChange={(e) => {
						props.onColorChange(props.id, props.color, e.target.value);
					}}
					value={props.name}
				/>
			</div>
			<div className="rows">
				{isPaletteVisible ? (
					<>
						<ColorRow
							onClick={props.addColor}
							className="split_compliment"
							colors={[props.color.clone().spin(150).toHexString(), props.color.clone().spin(210).toHexString()]}
						/>
						<ColorRow
							onClick={props.addColor}
							className="compliment"
							colors={[props.color.clone().spin(180).toHexString()]}
						/>
						<ColorRow
							onClick={props.addColor}
							className="split_progrssion"
							colors={progression(props.color.clone(), 6)}
						/>
					</>
				) : (
					<></>
				)}
				{isGradientVisible ? (
					<>
						<ColorRow onClick={props.addColor} className="hsv_gradient" colors={hsvGradient(props.color, 10)} />
						<ColorRow onClick={props.addColor} className="hsl_gradient" colors={hslGradient(props.color, 10)} />
						<ColorRow onClick={props.addColor} className="hsl_gradient" colors={monoGradient(props.color)} />
						{/* <ColorRow onClick={props.addColor} className="hsl_gradient" colors={monoHSVGradient(props.color)} /> */}
						{/* <ColorRow onClick={props.addColor} className="hsl_gradient" colors={monoHSLGradient(props.color)} /> */}
					</>
				) : (
					<></>
				)}
			</div>
			<div className="description">
				<div>Hex</div>
				<div>
					<input
						ref={hexInput}
						type="text"
						onBlur={(e) => {
							if (e.target.value) {
								props.onColorChange(props.id, tinycolor(e.target.value), props.name);
							}
						}}
					/>
				</div>
				<div>
					<CopyToClipboard
						text={props.color.toHexString()}
						onCopy={(e) => {
							console.log("Copied To Clipboard: " + props.color.toHexString());
						}}
					>
						<button>
							<Icons.Duplicate />
						</button>
					</CopyToClipboard>
				</div>

				<div>HSV</div>
				<div>
					<input
						type="text"
						value={Math.round(props.color.toHsv().h)}
						onChange={(e) => {
							if (e.target.value) {
								props.onColorChange(
									props.id,
									tinycolor({
										h: e.target.value,
										s: props.color.toHsv().s,
										v: props.color.toHsv().v,
									}),
									props.name
								);
							}
						}}
					/>
				</div>
				<div>
					<input
						type="text"
						value={Math.round(props.color.toHsv().s * 100)}
						onChange={(e) => {
							if (e.target.value) {
								props.onColorChange(
									props.id,
									tinycolor({
										h: props.color.toHsv().h,
										s: e.target.value / 100,
										v: props.color.toHsv().v,
									}),
									props.name
								);
							}
						}}
					/>
				</div>
				<div>
					<input
						type="text"
						value={Math.round(props.color.toHsv().v * 100)}
						onChange={(e) => {
							if (e.target.value) {
								props.onColorChange(
									props.id,
									tinycolor({
										h: props.color.toHsv().h,
										s: props.color.toHsv().s,
										v: e.target.value / 100,
									}),
									props.name
								);
							}
						}}
					/>
				</div>
				<div>
					<CopyToClipboard
						text={props.color.toHsvString()}
						onCopy={(e) => {
							console.log("Copied To Clipboard: " + props.color.toHsvString());
						}}
					>
						<button>
							<Icons.Duplicate />
						</button>
					</CopyToClipboard>
				</div>

				<div>HSL</div>
				<div>{parseInt(props.color.toHsl().h)}</div>
				<div>{parseInt(props.color.toHsl().s * 100)}</div>
				<div>{parseInt(props.color.toHsl().l * 100)}</div>
				<div>
					<CopyToClipboard
						text={props.color.toHslString()}
						onCopy={(e) => {
							console.log("Copied To Clipboard: " + props.color.toHslString());
						}}
					>
						<button>
							<Icons.Duplicate />
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
}

// function ColorGradientList(props) {

// }

function isValidJSON(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function ColorList(props) {
	const importInput = useRef();
	const [colorArray, setColorArray] = useState([
		{ id: 1, color: tinycolor("#ffffff"), name: "white" },
		{ id: 2, color: tinycolor("#00ffff"), name: "otherwhite" },
	]);

	useEffect(() => {
		if (props.colors) {
			let newColorArray = [];
			props.colors.map((color, index) => {
				newColorArray.push({ id: newColorArray.length + 1, color: tinycolor(color), name: "" });
			});
			setColorArray(newColorArray);
		}
	}, [props.colors]);

	function handleColorChange(id, newColor, name) {
		console.log("Handle color change " + id + " " + newColor.toHexString());
		let newColorArray = [];
		colorArray.map((entry, index) => {
			if (entry.id == id) {
				newColorArray.push({ id: id, color: newColor, name: name });
			} else {
				newColorArray.push(entry);
			}
		});
		setColorArray(newColorArray);
	}

	function addColor(color, name) {
		let newColorArray = [...colorArray, { id: colorArray.length + 1, color: color, name: name }];
		setColorArray(newColorArray);
	}

	function removeSwatch(id) {
		let newColorArray = [];
		colorArray.map((entry, index) => {
			if (entry.id != id) {
				newColorArray.push({ id: newColorArray.length + 1, color: entry.color, name: entry.name });
			}
		});
		setColorArray(newColorArray);
	}

	function toJSON() {
		let output = [];
		colorArray.map((entry, index) => {
			output.push({ color: entry.color.toHexString(), name: entry.name });
		});
		return JSON.stringify(output);
	}

	function fromJSON(str) {
		let newColorArray = [];
		JSON.parse(str).map((entry, index) => {
			newColorArray.push({ id: newColorArray.length + 1, color: tinycolor(entry.color), name: entry.name });
		});
		setColorArray(newColorArray);
	}

	return (
		<div className="color_list">
			<h2>
				ColorList{" "}
				<button
					onClick={() => {
						addColor(tinycolor.random(), "");
					}}
				>
					<Icons.CirclePlus />
				</button>
				<CopyToClipboard
					text={toJSON()}
					onCopy={(e) => {
						console.log("Copied To Clipboard: " + toJSON());
					}}
				>
					<button>
						<Icons.SquareDownload />
					</button>
				</CopyToClipboard>
			</h2>
			<ul>
				{colorArray.map((entry, index) => (
					<li key={"list" + index}>
						<ColorSwatch
							id={entry.id}
							key={entry.id}
							name={entry.name}
							color={entry.color}
							onColorChange={handleColorChange}
							addColor={addColor}
							onRemove={removeSwatch}
						></ColorSwatch>
					</li>
				))}
			</ul>
			<input ref={importInput} type="text" defaultValue="" />
			<button
				onClick={() => {
					fromJSON(importInput.current.value);
				}}
			>
				Import
			</button>
			<button
				onClick={() => {
					console.log("hello");
					let scss_vars = [];
					let css_vars = [];
					colorArray.map((entry, index) => {
						if (entry.name) {
							scss_vars.push("$color_" + entry.name + ": " + entry.color.toHslString() + ";");
							css_vars.push("\tcolor_" + entry.name + ": " + entry.color.toHslString() + ";");
							let monos = monoGradient(entry.color);
							monos.map((mono, index) => {
								scss_vars.push(
									"$color_" + entry.name + "_" + (index + 1) + "00: " + tinycolor(mono).toHslString() + ";"
								);
								css_vars.push(
									"\tcolor_" + entry.name + "_" + (index + 1) + "00: " + tinycolor(mono).toHslString() + ";"
								);
								console.log(tinycolor(mono));
							});
						}
					});

					let output = scss_vars.join("\n") + "\n\n:export {\n" + css_vars.join("\n") + "\n}";
					console.log(output);
				}}
			>
				Export SCSS
			</button>
		</div>
	);
}

export default function Color() {
	const [colorBase, setColorBase] = useState("hsv(180, 100%, 90%)");
	const [colorNames, setColorNames] = useState(() => {
		const saved = localStorage.getItem("colors_names");
		const initialValue = JSON.parse(saved);
		return initialValue ? initialValue : {};
	});
	const [starred, setStarred] = useState(() => {
		const saved = localStorage.getItem("colors_starred");
		const initialValue = JSON.parse(saved);
		return initialValue ? initialValue : [];
	});

	useEffect(() => {}, [colorBase]);

	function handleColor(clr) {
		setColorBase(clr);
	}

	function octad(startingColor) {
		let output = [];
		output = output.concat(tinycolor(startingColor).tetrad());
		let complements = [];
		output.map((clr, i) => {
			complements = complements.concat(clr.splitcomplement());
		});
		output = output.concat(complements);
		return output;
	}

	function progression(clr, steps) {
		clr = tinycolor(clr);
		let output = [];
		const clrHsv = clr.toHsv();
		for (var i = 0; i < steps; i++) {
			let hue = clrHsv.h + (360 / steps) * i;
			if (hue > 360) {
				hue = hue - 360;
			}
			output.push(tinycolor({ h: hue, s: clrHsv.s, v: clrHsv.v }));
		}
		return output;
	}

	function brewer(clr) {
		clr = tinycolor(clr);
		// let steps = [30, 60, 90, 80, 70];
		let steps = [30, 90, 180, 260, 330];
		let output = [clr];
		const clrHsv = clr.toHsv();
		for (var i = 0; i < steps.length; i++) {
			let hue = clrHsv.h + steps[i];
			if (hue > 360) {
				hue = hue - 360;
			}
			output.push(tinycolor({ h: hue, s: clrHsv.s, v: clrHsv.v }));
		}
		return output;
	}

	function handleOnStar($color) {
		console.log($color);
		// let newStarred = sortByHue([...starred, $color]);
		let newStarred = sortByHue(starred.concat($color));
		localStorage.setItem("colors_starred", JSON.stringify(newStarred));
		setStarred(newStarred);
	}

	function handleOnUnStar($color) {
		let newStarred = [...starred];
		const i = newStarred.indexOf($color);
		if (i > -1) {
			newStarred.splice(i, 1);
			localStorage.setItem("colors_starred", JSON.stringify(newStarred));
			setStarred(newStarred);
		}
	}

	function handleSetName(color, name) {
		let newColorNames = { ...colorNames };
		newColorNames[color] = name;
		localStorage.setItem("colors_names", JSON.stringify(newColorNames));
		setColorNames(newColorNames);
	}

	return (
		<div className="colors">
			<input
				type="text"
				value={colorBase}
				onChange={(e) => {
					setColorBase(e.target.value);
					console.log(e);
				}}
			/>

			<h2>Custom</h2>
			<ColorList />
			<ColorList colors={cleanVaporWave} />

			{/* <h2>Starred</h2>
			<ul>
				{starred.map((clr, index) => (
					<li key={"starred" + index}>
						<ColorSwatch
							key={tinycolor(clr).toHexString()}
							onStar={handleOnStar}
							onUnStar={handleOnUnStar}
							isStarred={starred.includes(tinycolor(clr).toHexString()) ? true : false}
							color={tinycolor(clr).toHexString()}
							setColor={handleColor}
							name={colorNames[tinycolor(clr).toHexString()]}
							setName={handleSetName}
						></ColorSwatch>
					</li>
				))}
			</ul>

			<input
				type="text"
				onChange={(e) => {
					localStorage.setItem("colors_starred", e.target.value);
					setStarred(JSON.parse(e.target.value));
				}}
				value={JSON.stringify(starred)}
			/>
			<input type="text" value={JSON.stringify(colorNames)} readOnly={true} /> */}
			{/* 
			<h2>Dark Vaporwave 84</h2>
			<ul>
				{cleanVaporWave.map((clr, index) => (
					<li key={"vaporwave" + index}>
						<ColorSwatch
							onStar={handleOnStar}
							onUnStar={handleOnUnStar}
							isStarred={starred.includes(tinycolor(clr).toHexString()) ? true : false}
							color={tinycolor(clr).toHexString()}
							setColor={handleColor}
						></ColorSwatch>
					</li>
				))}
			</ul> */}
		</div>
	);
}
