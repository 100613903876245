import React, { useState } from "react";
import { ButtonFancy, InputFancy } from "../../components/fancy";
import { Formik, Form, Field } from "formik";
import { apiCall, userCan } from "../../authentication";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
	VaporLayoutCenter,
	VaporButtonList,
	VaporWindow,
	VaporWindowTitle,
	VaporWindowContent,
	VaporInput,
	VaporButton,
	VaporInlineButton,
	VaporWindowDivider,
} from "../../Design/Vapor";

export default function ModalDuplicatePage(props) {
	const [typingTimeout, setTypingTimeout] = React.useState(0);
	const [typingCache, setTypingCache] = React.useState({ [props.page.current.path]: false });
	const [overridePlaceholder, setOverridePlaceholder] = React.useState();
	let navigate = useNavigate();

	const DuplicatePageSchema = Yup.object().shape({
		path: Yup.string()
			.required("Required")
			.test("path-exists", "Path already exists", async (value) => {
				if (typingTimeout) clearTimeout(typingTimeout);
				if (value in typingCache) {
					console.log("Done typing; Returning cached result ...");
					setOverridePlaceholder();
					return typingCache[value];
				}
				setOverridePlaceholder("Checking path ...");
				return new Promise((resolve, reject) => {
					setTypingTimeout(
						setTimeout(async () => {
							console.log("Done typing; Validating field ...");
							let result = await apiCall("GET", `/v1/pages/md/${value}`);
							setOverridePlaceholder();
							if (result.status === 404) {
								typingCache[value] = true;
								setTypingCache(typingCache);
								return resolve(true);
							} else {
								typingCache[value] = false;
								setTypingCache(typingCache);
								return resolve(false);
							}
						}, 1000)
					);
				});
			})
			.required("Path is required"),
	});

	return (
		<VaporWindow footer="">
			<Formik
				validateOnMount={true}
				initialValues={{
					path: props.page.current.path,
				}}
				validationSchema={DuplicatePageSchema}
				onSubmit={async (values) => {
					console.log("submit");
					console.log(values);
					console.log(props.page.current);
					let result = await apiCall("POST", `/v1/pages/md`, {
						path: values.path,
						content: props.page.current.content,
						content_draft: props.page.current.content_draft,
					});
					console.log(result);
					navigate("/txt/" + values.path);
					props.onClose();
				}}
			>
				{(formik) => (
					<Form>
						<>
							<VaporWindowTitle icon={null}>
								<h1>Duplicate Page</h1>
							</VaporWindowTitle>
							<VaporWindowContent>
								<p>Enter the destination path</p>
								<Field
									key="path_input"
									type="text"
									focusOnMount={true}
									name="path"
									defaultPlaceholder="Path"
									placeholder="Path"
									overridePlaceholder={overridePlaceholder}
									component={VaporInput}
									onKeyEnter={(e) => {
										formik.submitForm();
									}}
									onKeyEscape={(e) => {
										props.onClose();
									}}
								/>
								<VaporButtonList direction="vertical">
									<VaporButton
										key="email_button"
										type="submit"
										disabled={!formik.isValid || formik.isValidating ? true : false}
										isSubmitting={formik.isSubmitting}
										onClick={() => {
											console.log(formik.errors);
											console.log(props.page);
										}}
									>
										Duplicate Page
									</VaporButton>
								</VaporButtonList>
							</VaporWindowContent>
						</>
					</Form>
				)}
			</Formik>
		</VaporWindow>
	);
}
