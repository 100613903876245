import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Settings } from "../../settings.js";
import { useParams } from "react-router-dom";
import MarkdownContent from "../../components/MarkdownContent";
import { apiCall, userCan } from "../../authentication";
// import CodeMirror from "@uiw/react-codemirror";
// import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
import { languages } from "@codemirror/language-data";
// import { EditorView } from "@codemirror/view";
import { autocompletion, completionKeymap } from "@codemirror/autocomplete";
// import { CompletionContext } from "@codemirror/autocomplete";
import { browserSpellCheck } from "./browserspellcheck";
import emoji from "emoji-dictionary";

import NotFound from "../NotFound/index.js";
import DefaultLayout from "../../layouts/default.js";
import { VaporLayoutDefault, VaporButton, VaporButtonList } from "../../Design/Vapor.js";

import { ButtonFancy, InputFancy } from "../../components/fancy";
import TableOfContents, { parseHeadings } from "../../components/TableOfContents";
import Spinner from "../../components/Spinner";
import FancyIcon from "../../components/FancyIcon";
import FancyModal from "../../components/FancyModal";

import ModalDuplicatePage from "./ModalDuplicatePage";

import Editor from "./Editor";

import classNames from "classnames";
import "./style.scss";

// https://codemirror.net/6/docs/ref/#tooltip
// https://codesandbox.io/s/zjm58?file=/src/browser-spell-check.js

// https://www.npmjs.com/package/rousseau
// https://www.npmjs.com/package/typo-js
// https://www.npmjs.com/package/proofreader
// https://github.com/btford/write-good
// nice toc https://reactrouter.com/docs/en/v6/getting-started/overview

function emojiCompletions(context) {
	let word = context.matchBefore(/:[\w]*/);
	if (!word) {
		return false;
	}
	if (word.from == word.to && !context.explicit) return null;
	let options = [
		// { label: "match", type: "keyword" },
		// { label: "hello", type: "variable", info: "(World)" },
		// { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" },
		// { label: ":smiley:", type: "text", apply: ":smiley:" },
	];

	for (let i = 0; i < emoji.names.length; i++) {
		options.push({
			label: ":" + emoji.names[i] + ":",
			type: "text",
			apply: ":" + emoji.names[i] + ":",
			detail: emoji.getUnicode(emoji.names[i]),
		});
		//console.log(emoji.names[i]);
	}
	return {
		from: word.from,
		options: options,
	};
}

export default function MarkdownPage(props) {
	//const [content, setContent] = useState(null);
	//const [page, setPage] = useState();
	const [path, setPath] = useState(null);
	const [view, setView] = useState(null);
	const [modalDuplicatePageOpen, setModalDuplicatePageOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isEditing, setIsEditing] = useState(false);
	const [isNotFound, setIsNotFound] = useState(false);
	const [tocHeadings, setTocHeadings] = useState([]);
	const TableOfContentsRef = useRef();
	const page = useRef(null);
	let urlParams = useParams();
	let canWrite = userCan("PagesWrite");
	let navigate = useNavigate();

	async function create(path) {
		await apiCall("POST", `/v1/pages/md`, {
			path: path,
			content: null,
			content_draft: "Write something meaningful.",
		});
		loadPage(path);
	}

	async function remove(path) {
		await apiCall("DELETE", `/v1/pages/md/` + path);
		navigate(`/txt`);
		//loadPage(path);
	}

	async function loadPage(path) {
		setIsLoading(true);
		let result = await apiCall("GET", `/v1/pages/md/${path}`);
		if (result.status !== 200) {
			setIsNotFound(true);
			setIsLoading(false);
			return;
		}
		page.current = result.data;

		setIsNotFound(false);
		setIsLoading(false);
	}

	useEffect(() => {
		setPath(urlParams["*"]);
		loadPage(urlParams["*"]);
	}, [urlParams]);

	function afterRender() {
		// console.log("Looking for title");
		// Extract document title from H1 or path
		let h1 = document.querySelector("h1");
		if (h1) {
			document.title = Settings.TITLE_PREFIX + h1.textContent;
		} else {
			document.title = Settings.TITLE_PREFIX + urlParams["*"];
		}
		console.log("Set title to: " + document.title);
		//TableOfContentsRef.current.getAlert();
		setTocHeadings(parseHeadings());
	}

	let editHeader = (
		<div className={"EditHeader"}>
			{/* <FancyIcon icon="GLYPHICON_HALFLING_SQUARE_PENCIL"></FancyIcon> */}
			<VaporButtonList>
				<VaporButton
					type="button"
					padding={100}
					onClick={() => {
						navigate("/txt/" + path + "!" + "edit");
						//setIsEditing(true);
					}}
					icon="GLYPHICON_SQUARE_EDIT"
				>
					Edit
				</VaporButton>
				<VaporButton
					type="button"
					padding={100}
					onClick={() => {
						//setIsEditing(true);
						setModalDuplicatePageOpen(true);
					}}
					icon="GLYPHICON_SQUARE_EMPTY_PLUS"
				>
					Duplicate
				</VaporButton>
				<VaporButton
					type="button"
					doubleConfirm={true}
					padding={100}
					onClick={() => {
						//remove(page.current.path);
					}}
					icon="GLYPHICON_SQUARE_EMPTY_REMOVE"
				>
					Remove
				</VaporButton>
			</VaporButtonList>
		</div>
	);

	// Page is loading
	if (isLoading) {
		return (
			<DefaultLayout>
				<Spinner></Spinner>
			</DefaultLayout>
		);
	}

	// Page does not exist, at all
	if (isNotFound) {
		return (
			<NotFound>
				{canWrite ? (
					<div>
						<p>No page exists at this path. Perhaps you should write something?</p>
						<button
							type="button"
							className={"ButtonFancy transparent"}
							onClick={() => {
								create(urlParams["*"]);
							}}
						>
							Create a New Page
						</button>
					</div>
				) : (
					<div>Nope.</div>
				)}
			</NotFound>
		);
	}

	// Page exists, but has no content
	if (page.current && !page.current.content && !isEditing) {
		return (
			<NotFound>
				{canWrite ? (
					<div>
						<p>A page exists here, but there is no published content.</p>
						{editHeader}
					</div>
				) : (
					<div>Nope.</div>
				)}
			</NotFound>
		);
	}

	if (!view) {
		return (
			<VaporLayoutDefault>
				<FancyModal
					open={modalDuplicatePageOpen}
					onClose={() => {
						setModalDuplicatePageOpen(false);
					}}
				>
					<ModalDuplicatePage
						page={page}
						onClose={() => {
							setModalDuplicatePageOpen(false);
						}}
					/>
				</FancyModal>

				<div className={"VaporMarkdownPage"}>
					<div className={"MarkdownBody"}>
						{canWrite ? editHeader : ""}
						<MarkdownContent files={page.current.files} afterRender={afterRender}>
							{page.current.content}
						</MarkdownContent>
					</div>
				</div>
			</VaporLayoutDefault>
		);
	}
}
