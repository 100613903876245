import React, { memo } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./Particles.scss";

export const VaporParticles = memo((props) => {
	const particlesInit = async (main) => {
		console.log(main);
		// you can initialize the tsParticles instance (main) here, adding custom shapes or presets
		await loadFull(main);
	};

	const particlesLoaded = (container) => {
		console.log(container);
	};

	return (
		<Particles
			id="tsparticles"
			init={particlesInit}
			loaded={particlesLoaded}
			options={{
				fpsLimit: 30,
				particles: {
					color: {
						value: "#E30066", //"#8EF9F3",
					},
					links: {
						color: "#E30066", //"#8EF9F3",
						distance: 120,
						enable: true,
						opacity: 0.5,
						width: 1,
						triangles: {
							enable: true,
							opacity: 0.05,
						},
					},
					collisions: {
						enable: true,
					},
					move: {
						direction: "none",
						enable: true,
						outMode: "out",
						random: false,
						speed: 1.5,
						straight: false,
						bounce: false,
						// vibrate: true,
						attract: {
							enable: false,
							rotateX: 600,
							rotateY: 1200,
						},
					},
					number: {
						density: {
							enable: true,
							area: 800,
						},
						value: 85,
					},
					opacity: {
						value: 0.5,
					},
					shape: {
						type: "circle",
					},
					size: {
						random: true,
						value: 3,
					},
				},
				detectRetina: true,
			}}
		/>
	);
});
