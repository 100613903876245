import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Settings } from "../../settings.js";
import { useParams } from "react-router-dom";
import MarkdownContent from "../../components/MarkdownContent";
import { apiCall, userCan } from "../../authentication";
import emoji from "emoji-dictionary";
import DefaultLayout from "../../layouts/default.js";
import Spinner from "../../components/Spinner";
import FancyIcon from "../../components/FancyIcon";
import { VaportLayoutFullScreen, VaporButton, VaporButtonList } from "../../Design/Vapor.js";
import CodeMirrorEditor from "../../components/CodeMirror/editor.tsx";

import { FilePond, File, registerPlugin } from "react-filepond";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEditor from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";

// import {
// 	// Pintura editor components needed for integration with FilePond
// 	openEditor,
// 	processImage,
// 	createDefaultImageReader,
// 	createDefaultImageWriter,
// 	legacyDataToImageState,

// 	// Import the editor default configuration
// 	getEditorDefaults,
// } from Pintura;

import ExampleFilePond from "./ExampleFilePond.js";

import {
	// editor
	openEditor,
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,
	createDefaultImageOrienter,
	createDefaultShapePreprocessor,
	legacyDataToImageState,
	processImage,

	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,
	plugin_annotate,
	plugin_annotate_locale_en_gb,
	markup_editor_defaults,
	markup_editor_locale_en_gb,
} from "pintura";
import "pintura/pintura.css";

import classNames from "classnames";
import "./Editor.scss";

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
// registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageEditor, FilePondPluginFilePoster);
registerPlugin(FilePondPluginImageEditor, FilePondPluginFilePoster);
// Pintura
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

// https://codemirror.net/6/docs/ref/#tooltip
// https://codesandbox.io/s/zjm58?file=/src/browser-spell-check.js

// https://www.npmjs.com/package/rousseau
// https://www.npmjs.com/package/typo-js
// https://www.npmjs.com/package/proofreader
// https://github.com/btford/write-good
// nice toc https://reactrouter.com/docs/en/v6/getting-started/overview

const resetTimeout = (id, newID) => {
	clearTimeout(id);
	return newID;
};

function emojiCompletions(context) {
	let word = context.matchBefore(/:[\w]*/);
	if (!word) {
		return false;
	}
	if (word.from == word.to && !context.explicit) return null;
	let options = [
		// { label: "match", type: "keyword" },
		// { label: "hello", type: "variable", info: "(World)" },
		// { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" },
		// { label: ":smiley:", type: "text", apply: ":smiley:" },
	];

	for (let i = 0; i < emoji.names.length; i++) {
		options.push({
			label: ":" + emoji.names[i] + ":",
			type: "text",
			apply: ":" + emoji.names[i] + ":",
			detail: emoji.getUnicode(emoji.names[i]),
		});
		//console.log(emoji.names[i]);
	}
	return {
		from: word.from,
		options: options,
	};
}

export default function Editor(props) {
	//const [content, setContent] = useState(null);
	//const [page, setPage] = useState();
	const [path, setPath] = useState(null);
	const [view, setView] = useState(null);
	const [tab, setTab] = useState("editor");
	const [timeoutSave, setTimeoutSave] = useState(null);
	const [saveStatus, setSaveStatus] = useState("Saved");
	const [isLoading, setIsLoading] = useState(true);
	const [isEditing, setIsEditing] = useState(false);
	const [isNotFound, setIsNotFound] = useState(false);
	const [tocHeadings, setTocHeadings] = useState([]);

	const [pageFiles, setPageFiles] = useState([]);
	const [files, setFiles] = useState([]);

	const TableOfContentsRef = useRef();
	const Pond = useRef();
	const page = useRef(null);
	let urlParams = useParams();
	let canWrite = userCan("PagesWrite");
	let navigate = useNavigate();
	//const editorConfig = getEditorDefaults();

	async function onMarkdownChange(value) {
		// console.log("value:", value);
		if (page.current.content_draft != value) {
			page.current.content_draft = value;
			setSaveStatus("Unsaved");
			setTimeoutSave(resetTimeout(timeoutSave, setTimeout(save, 5000)));
		}
		//setContent(value);
	}

	async function fileCompletetions(context) {
		let word = context.matchBefore(/!\[\][\w]*/);
		// console.log(word);
		if (!word) {
			return false;
		}
		if (word.from == word.to && !context.explicit) return null;
		let options = [];
		// for (let i = 0; i < emoji.names.length; i++) {
		for (const file of page.current.files) {
			options.push({
				label: "![]" + file.filename,
				type: "text",
				apply: "![](" + file.filename + ")",
				detail: "",
			});
			//console.log(emoji.names[i]);
		}
		return {
			from: word.from,
			options: options,
		};
	}

	async function create(path) {
		await apiCall("POST", `/v1/pages/md`, {
			path: path,
			content: null,
			content_draft: "Write something meaningful.",
		});
		loadPage(path);
	}

	async function filesRefresh() {
		let refreshedFiles = await apiCall("POST", `/v1/pages/files_refresh/` + page.current.path);
		console.log(refreshedFiles);
		setPageFiles(refreshedFiles.data);
	}

	async function remove(path) {
		await apiCall("DELETE", `/v1/pages/md/` + path);
		navigate(`/txt`);
		//loadPage(path);
	}

	async function save() {
		setSaveStatus("Saving");
		await apiCall("PUT", `/v1/pages/md/${path}`, {
			path: page.current.path,
			content: page.current.content,
			content_draft: page.current.content_draft,
		});
		setSaveStatus("Saved");
		console.log("saved");
	}

	async function publish() {
		await apiCall("PUT", `/v1/pages/md/${path}`, {
			path: page.current.path,
			content: page.current.content_draft,
			content_draft: page.current.content_draft,
		});
		await loadPage(page.current.path);
		navigate("/txt/" + path);
		console.log("Published");
	}

	async function loadPage(path) {
		setIsLoading(true);
		let result = await apiCall("GET", `/v1/pages/md/${path}`);
		if (result.status !== 200) {
			setIsNotFound(true);
			setIsLoading(false);
			return;
		}
		page.current = result.data;
		setPageFiles(result.data.files);
		let pondFiles = [];
		for (var f of result.data.files) {
			console.log(f);
			pondFiles.push({
				// the server file reference
				source: f.filename,

				// set type to local to indicate an already uploaded file
				options: {
					type: "local",
					file: {
						name: f.filename,
						size: f.size,
					},
				},
			});
		}
		//setFiles(pondFiles);

		setIsNotFound(false);
		// if (isEditing) {
		// 	setContent(result.data.content_draft);
		// } else {
		// 	setContent(result.data.content);
		// }
		setIsLoading(false);
	}

	useEffect(() => {
		const [p, v] = urlParams["*"].split("!");
		setPath(p);
		setView(v);
		loadPage(p);
		document.title = Settings.TITLE_PREFIX + "Editing '" + p + "'";
	}, [urlParams]);

	useEffect(() => {
		return function cleanup() {
			clearTimeout(timeoutSave);
		};
	}, [timeoutSave]);

	// Page is loading
	if (isLoading) {
		return (
			<DefaultLayout>
				<Spinner></Spinner>
			</DefaultLayout>
		);
	}

	return (
		<VaportLayoutFullScreen>
			<div className={"VaporMarkdownEditor"}>
				<div className={"Header"}>
					<div className={"Title"}>HX.IO / TXT</div>
					<div className={"Tabs"}>
						<ul>
							<li className={classNames({ active: tab === "editor" ? true : false })}>
								<button
									className={"LinkButton"}
									onClick={() => {
										setTab("editor");
									}}
								>
									Editor
								</button>
							</li>
							<li className={classNames({ active: tab === "files" ? true : false })}>
								<button
									className={"LinkButton"}
									onClick={() => {
										setTab("files");
									}}
								>
									Files
								</button>
							</li>
							<li className={classNames({ active: tab === "preview" ? true : false })}>
								<button
									className={"LinkButton"}
									onClick={() => {
										setTab("preview");
									}}
								>
									Preview
								</button>
							</li>
						</ul>
					</div>
				</div>
				<div className={"Body"}>
					<div className={classNames("Editor", { active: tab === "editor" ? true : false })}>
						<div className={"CodeMirror"}>
							<CodeMirrorEditor initialDoc={page.current.content_draft} onChange={onMarkdownChange} />
							{/* <CodeMirror
								theme={oneDark}
								value={page.current.content_draft}
								height="100%"
								width="100%"
								extensions={[
									markdown({ base: markdownLanguage, codeLanguages: languages, extensions: [] }),
									EditorView.lineWrapping,
									browserSpellCheck(),
									autocompletion({ override: [emojiCompletions, fileCompletetions] }),
								]}
								onChange={onMarkdownChange}
							/> */}
						</div>
					</div>
					<div className={classNames("Files", { active: tab === "files" ? true : false })}>
						<div className="Files">
							{/* File stuff here
						<button
							onClick={() => {
								filesRefresh(page.current.path);
							}}
						>
							Refresh
						</button>
						<ul>
							{pageFiles.map((file) => (
								<li key={file.key}>
									<FileItem file={file} refresh={filesRefresh} />
								</li>
							))}
						</ul> */}
							{/* <FilePond
							files={files}
							ref={Pond}
							onupdatefiles={setFiles}
							allowMultiple={true}
							// allowRemove={true}
							// allowRevert={false}
							maxFiles={100}
							name="files"
							labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
							onremovefile={(e) => {
								console.log("Removing");
							}}
							beforeRemoveFile={async (item) => {
								let response = await apiCall(
									"DELETE",
									`/v1/pages/files/${page.current.path}/${item.file.name}`
								);
								await filesRefresh();
								Pond.current.removeFile(item.file);
								return false;
							}}
							server={{
								// https://github.com/pqina/filepond/issues/58
								timeout: 99999999,
								process: async function (fieldName, file, metadata, load, error, progress, abort) {
									//console.log(file);
									console.log(files);
									var filepondRequest = new XMLHttpRequest();
									var filepondFormData = new FormData();
									let response = await apiCall(
										"PUT",
										`/v1/pages/files/` + page.current.path + "/" + file.name
									);
									file.additionalData = response.data.fields;
									// append the FormData() in the order below. Changing the order
									// would result in 403 bad request error response from S3.
									// No other fields are needed apart from these ones. Appending extra
									// fields to the formData would also result in error responses from S3.
									for (var field in file.additionalData) {
										filepondFormData.append(field, file.additionalData[field]);
									}
									filepondFormData.append("file", file);
									filepondRequest.upload.onprogress = function (e) {
										progress(e.lengthComputable, e.loaded, e.total);
									};
									filepondRequest.open("POST", response.data.url);
									filepondRequest.onload = function () {
										filesRefresh();
										console.log(file);
										load(`${file.additionalData.key}`);
									};
									// filepondRequest.onremove = function () {
									// 	console.log("REMOVE");
									// };
									filepondRequest.send(filepondFormData);
									return {
										abort: function () {
											filepondRequest.abort();
											abort();
										},
									};
								},
							}}
						/> */}
							<ExampleFilePond />
						</div>
					</div>
					<div className={classNames("Preview", { active: tab === "preview" ? true : false })}>
						<MarkdownContent files={page.current.files}>{page.current.content_draft}</MarkdownContent>
					</div>
				</div>
				<div className={"Footer"}>
					<div className={classNames("AutoSave", saveStatus)}>
						<FancyIcon className="Cloud" icon="GLYPHICON_BASIC_CLOUD" />
						<FancyIcon className="Check" icon="GLYPHICON_BASIC_CLOUD_CHECK" />
						<FancyIcon className="Refresh" icon="GLYPHICON_BASIC_CLOUD_REFRESH" />
					</div>
					<div className={"Toolbar"}>
						<VaporButtonList marginTop={"000"} marginBottom={"000"} flexJustify={"right"}>
							<VaporButton
								type="button"
								isTransparent={true}
								onClick={() => {
									navigate("/txt/" + path);
									setIsEditing(!isEditing);
								}}
								marginTop={"000"}
								marginBottom={"000"}
								padding={"100"}
							>
								Cancel
							</VaporButton>
							<VaporButton
								type="button"
								onClick={() => {
									publish();
								}}
								marginTop={"000"}
								marginBottom={"000"}
								padding={"100"}
							>
								Publish
							</VaporButton>
						</VaporButtonList>
					</div>
				</div>
			</div>
		</VaportLayoutFullScreen>
	);
}

export function FileItem(props) {
	async function remove() {
		console.log("[-] Deleting '{}'");
		let response = await apiCall("DELETE", `/v1/pages/files/${props.file.key}`);
		props.refresh();
		console.log(response);
	}

	return (
		<div>
			<a href={props.file.presigned_url} target="_blank">
				{props.file.filename}
			</a>
			(
			<button className={"LinkButton"} onClick={remove}>
				Delete
			</button>
			)
		</div>
	);
}
