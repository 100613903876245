import React, { forwardRef, useEffect, useRef, useImperativeHandle } from "react";
import "./TableOfContents.scss";

// https://codepen.io/emgoto/pen/jOyORjZ
// https://www.emgoto.com/react-table-of-contents/

//https://github.com/Ginxo/chain-status/blob/a38419858f565376de731234a3d7a9ffadbba59c/packages/webpage/src/components/current-status/CurrentStatusMenu.tsx#L78
//https://reactjs.org/docs/hooks-reference.html
//https://reactjs.org/docs/hooks-faq.html#how-to-avoid-passing-callbacks-down

const Headings = ({ headings, activeId }) => {
	return (
		<ul>
			{headings.map((heading) => (
				<li key={heading.id} className={heading.id === activeId ? "active" : ""}>
					<a
						href={`#${heading.id}`}
						onClick={(e) => {
							e.preventDefault();
							document.querySelector(`#${heading.id}`).scrollIntoView({
								behavior: "smooth",
							});
						}}
					>
						{heading.title}
					</a>
					{heading.items.length > 0 && (
						<ul>
							{heading.items.map((child) => (
								<li key={child.id} className={child.id === activeId ? "active" : ""}>
									<a
										href={`#${child.id}`}
										onClick={(e) => {
											e.preventDefault();
											document.querySelector(`#${child.id}`).scrollIntoView({
												behavior: "smooth",
											});
										}}
									>
										{child.title}
									</a>
								</li>
							))}
						</ul>
					)}
				</li>
			))}
		</ul>
	);
};

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
	const [nestedHeadings, setNestedHeadings] = React.useState([]);

	React.useEffect(() => {
		const headingElements = Array.from(document.querySelectorAll("main h2, main h3"));

		// Created a list of headings, with H3s nested
		const newNestedHeadings = getNestedHeadings(headingElements);
		setNestedHeadings(newNestedHeadings);
	}, []);

	return { nestedHeadings };
};

export function parseHeadings() {
	const headingElements = Array.from(document.querySelectorAll("main h2, main h3"));
	return getNestedHeadings(headingElements);
}

const getNestedHeadings = (headingElements) => {
	const nestedHeadings = [];

	headingElements.forEach((heading, index) => {
		const { innerText: title, id } = heading;

		if (heading.nodeName === "H2") {
			nestedHeadings.push({ id, title, items: [] });
		} else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
			nestedHeadings[nestedHeadings.length - 1].items.push({
				id,
				title,
			});
		}
	});

	return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
	const headingElementsRef = React.useRef({});
	React.useEffect(() => {
		const callback = (headings) => {
			headingElementsRef.current = headings.reduce((map, headingElement) => {
				map[headingElement.target.id] = headingElement;
				return map;
			}, headingElementsRef.current);

			// Get all headings that are currently visible on the page
			const visibleHeadings = [];
			Object.keys(headingElementsRef.current).forEach((key) => {
				const headingElement = headingElementsRef.current[key];
				if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
			});
			//console.log(visibleHeadings);
			const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

			// If there is only one visible heading, this is our "active" heading
			if (visibleHeadings.length === 1) {
				setActiveId(visibleHeadings[0].target.id);
				// If there is more than one visible heading,
				// choose the one that is closest to the top of the page
			} else if (visibleHeadings.length > 1) {
				const sortedVisibleHeadings = visibleHeadings.sort(
					(a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
				);

				setActiveId(sortedVisibleHeadings[0].target.id);
			}
		};

		const observer = new IntersectionObserver(callback, { root: document.querySelector("iframe"), rootMargin: "500px" });

		const headingElements = Array.from(document.querySelectorAll("h2, h3"));

		headingElements.forEach((element) => observer.observe(element));

		return () => observer.disconnect();
	}, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
export default function TableOfContents(props) {
	const [activeId, setActiveId] = React.useState();
	useIntersectionObserver(setActiveId);

	return (
		<div className={"TableOfContents"}>
			<nav aria-label="Table of contents">
				{/* <Headings headings={nestedHeadings} activeId={activeId} /> */}
				<Headings headings={props.headings} activeId={activeId} />
			</nav>
		</div>
	);
}

// export default function TableOfContents(props) {
// 	const [activeId, setActiveId] = React.useState();
// 	const { nestedHeadings } = useHeadingsData();
// 	useIntersectionObserver(setActiveId);

// 	function test() {
// 		console.log("TEST WORKED");
// 	}

// 	useEffect(() => {
// 		console.log(nestedHeadings);
// 		console.log(props.headings);
// 	});

// 	return (
// 		<div className={"TableOfContents"}>
// 			<nav aria-label="Table of contents">
// 				{/* <Headings headings={nestedHeadings} activeId={activeId} /> */}
// 				<Headings headings={nestedHeadings} activeId={activeId} />
// 			</nav>
// 		</div>
// 	);
// }

// export const TOC = forwardRef((props, ref) => {
// 	const [activeId, setActiveId] = React.useState();
// 	const [nestedHeadings, setNestedHeadings] = React.useState();
// 	const { nestedHeadings } = useHeadingsData();
// 	useIntersectionObserver(setActiveId);

// 	// useEffect(() => {
// 	// 	const { nestedHeadings } = useHeadingsData();
// 	// 	setNestedHeadings(nestedHeadings);
// 	// });

// 	function test() {
// 		console.log("TEST WORKED");
// 	}

// 	useImperativeHandle(ref, () => ({
// 		getAlert() {
// 			console.log("HELLO");
// 		},
// 	}));

// 	return (
// 		<div className={"TableOfContents"}>
// 			test
// 			<nav aria-label="Table of contents">
// 				<Headings headings={nestedHeadings} activeId={activeId} />
// 			</nav>
// 		</div>
// 	);
// });
