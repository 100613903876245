import React, { Children, useState, useRef, useEffect, memo } from "react";
import PropTypes from "prop-types";
import FancyIcon from "../components/FancyIcon";
import classNames from "classnames";
import { VaporLayoutCenter, VaportLayoutFullScreen } from "./Layouts/Layouts";
import { VaporLayoutDefault } from "./Layouts/Default";
import { VaporInput } from "./Input";
import { VaporButton, VaporButtonList, VaporInlineButton } from "./Buttons";
import { VaporWindow, VaporWindowContent, VaporWindowDivider, VaporWindowTitle } from "./Window";
import { VaporParticles } from "./Particles";

import "./Styles/Vapor.scss";

// https://stackoverflow.com/questions/58926195/how-to-make-vantajs-wave-work-with-react-app
// https://codepen.io/tboskk/pen/zYBXKMa
// https://codepen.io/YusukeNakaya/pen/OGwmOV
// https://codepen.io/tsuhre/pen/GLRdKP
// https://codepen.io/pimskie/pen/BEmGez

export { VaporLayoutCenter, VaportLayoutFullScreen, VaporLayoutDefault };
export { VaporButton, VaporButtonList, VaporInlineButton };
export { VaporInput };
export { VaporWindow, VaporWindowContent, VaporWindowDivider, VaporWindowTitle };
export { VaporParticles, sizedClasses };

let sizedClasses = {
	marginTop: "vapor_margin-top-",
	marginBottom: "vapor_margin-bottom-",
	padding: "vapor_padding-",
};

export function VaporClasses(props) {
	let outputClasses = [];
	for (const [key, value] of Object.entries(sizedClasses)) {
		if (props[key]) {
			outputClasses.push(value + props[key]);
		}
	}
	return outputClasses;
}

export function VaporCleanProps(props, fields) {
	let outputProps = {};
	for (const [key, value] of Object.entries(props)) {
		if (!fields.includes(key)) {
			outputProps[key] = value;
		}
	}
	return outputProps;
}
