import React, { useState, useEffect } from "react";
import { geoEqualEarth, geoOrthographic, geoEquirectangular, geoPath } from "d3-geo";
import { feature } from "topojson-client";

// https://github.com/deldersveld/topojson
// https://github.com/zimrick/react-svg-maps-tutorial/blob/master/components/WorldMap.js

// const projection = geoOrthographic()
// const projection = geoOrthographic()
// 	.rotate([-200, -18.3908])
// 	// .center([-157.9332, 21.3908])
// 	// .center([-157.9332, 21.3908])
// 	// .rotate([-157.9332, 21.3908, 0])
// 	// .scale(160)
// 	.translate([800 / 2, 450 / 2]);

// const projection = geoEqualEarth()
// 	.scale(160)
// 	.translate([800 / 2, 450 / 2]);

var center = [parseInt(-157.9332) * -1, parseInt(21.3908) * -1];
const projection = geoEquirectangular();
// .rotate(center)
// .translate([800 / 2, 450 / 2]);

const WorldMap = (props) => {
	const [geographies, setGeographies] = useState([]);

	useEffect(() => {
		fetch("/data/worldmap-110m.json").then((response) => {
			if (response.status !== 200) {
				console.log(`There was a problem: ${response.status}`);
				return;
			}
			response.json().then((worlddata) => {
				setGeographies(feature(worlddata, worlddata.objects.countries).features);
			});
		});
		console.log(props);
	}, []);

	// const handleCountryClick = (countryIndex) => {
	// 	console.log("Clicked on country: ", geographies[countryIndex]);
	// };

	// const handleMarkerClick = (i) => {
	// 	console.log("Marker: ", cities[i]);
	// };

	return (
		<div className="worldmap">
			{/* <svg width={800} height={450} viewBox="0 0 800 450"> */}
			<svg width={800} height={450} viewBox="0 0 800 450">
				<g className="countries">
					{geographies.map((d, i) => (
						<path
							key={`path-${i}`}
							d={geoPath().projection(
								projection
									.rotate([parseInt(props.center[0]) * -1, 0]) //.rotate([parseInt(props.center[0]) * -1, parseInt(props.center[1]) * -1])
									.translate([800 / 2, 450 / 2])
							)(d)}
							className="country"
							fill={"transparent"}
							stroke={"#00FF00"}
							strokeWidth={2}
							// onClick={() => handleCountryClick(i)}
						/>
					))}
				</g>
				<g className="markers">
					{/* {cities.map((city, i) => (
						<circle
							key={`marker-${i}`}
							cx={projection(city.coordinates)[0]}
							cy={projection(city.coordinates)[1]}
							r={city.population / 3000000}
							fill="#E91E63"
							stroke="#FFFFFF"
							className="marker"
							// onClick={() => handleMarkerClick(i)}
						/>
					))} */}
					{props.markers.map((marker, i) => (
						<circle
							key={`marker-${i}`}
							cx={projection(marker.coordinates)[0]}
							cy={projection(marker.coordinates)[1]}
							r={10}
							fill="rgba(255,255,255,.15)"
							stroke="rgba(255,0,255,.75)"
							strokeWidth={5}
							className="marker"
							// onClick={() => handleMarkerClick(i)}
						/>
					))}
				</g>
			</svg>
		</div>
	);
};

export default WorldMap;
