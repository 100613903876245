import jwt_decode from "jwt-decode";
import { Settings } from "../settings.js";

export async function userCan(verb) {
	let accessRights = [];
	try {
		accessRights = JSON.parse(localStorage.getItem("currentUser")).decoded.user.access_rights;
	} catch {}
	if (accessRights.includes(verb)) {
		return true;
	}
	return false;
}

export async function apiCall(method, requestPath, requestBody) {
	let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
	let token = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")).access_token : "";
	const timestampCurrent = Date.now() / 1000;

	if (currentUser) {
		// Handle refresh logic, refresh 30 seconds early to account for potential clock drift
		if (timestampCurrent > currentUser.decoded.exp - 30 && timestampCurrent < currentUser.decoded.refresh) {
			console.log("API Warning: Refreshing token ...");
			const refreshRequestOptions = { method: "GET", headers: { Authorization: "bearer " + token } };
			let response = await fetch(`${Settings.HXIO_API_ENDPOINT}/v1/sessions/refresh`, refreshRequestOptions);
			if (response.status === 200) {
				console.log("API: Resfresh succeeded");
				let data = await response.json();
				var decoded = jwt_decode(data.access_token);
				data.decoded = decoded;
				// Reset our auth variables for the coming API request
				currentUser = data;
				token = data.access_token;
				localStorage.setItem("currentUser", JSON.stringify(data));
			} else {
				// Something went wrong refreshing the token...
				console.log("API Error: Something went wrong refreshing the authorization token");
			}
		}
	}

	const requestOptions = {
		method: method,
		body: requestBody ? JSON.stringify(requestBody) : null,
		headers: { "Content-Type": "application/json", Authorization: "bearer " + token },
	};
	let response = await fetch(`${Settings.HXIO_API_ENDPOINT}${requestPath}`, requestOptions);
	let output = { status: response.status, success: false };
	if (output.status === "401") {
		console.log("API Error: Received a 401 (unauthorized) response");
		return output;
	}
	if (output.status !== 200) {
		console.log(output);
		return output;
	}
	output.success = true;
	output.data = await response.json();
	console.log("API Response:");
	console.log(output);
	return output;
}

export async function loginUser(email, password, dispatch) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email, password }),
	};

	console.log(requestOptions);
	try {
		dispatch({ type: "REQUEST_LOGIN" });
		let response = await fetch(`${Settings.HXIO_API_ENDPOINT}/v1/sessions/signin`, requestOptions);
		let data = await response.json();
		if (response.status === 200) {
			console.log(data);
			var decoded = jwt_decode(data.access_token);
			console.log(decoded);
			data.decoded = decoded;
			dispatch({ type: "LOGIN_SUCCESS", payload: data });
			localStorage.setItem("currentUser", JSON.stringify(data));
			return response;
		} else {
			console.log("Login Error: Response was " + response.status);
			console.log(data);
			dispatch({ type: "LOGIN_ERROR", error: data.detail });
			return response;
		}
	} catch (error) {
		dispatch({ type: "LOGIN_ERROR", error: error });
		console.log(error);
	}
}

export async function logout(dispatch) {
	dispatch({ type: "LOGOUT" });
	localStorage.removeItem("currentUser");
	localStorage.removeItem("token");
}
