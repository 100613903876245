import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
//import rehypeParse from "rehype-parse";
import rehypeSlug from "rehype-slug";
//import rehypeStringify from "rehype-stringify";
//import rehypeToc from "@jsdevtools/rehype-toc";
import { CheckboxFancy } from "./fancy";
import { Link } from "react-router-dom";
import emoji from "emoji-dictionary";
import "./MarkdownContent.scss";
import "../styles/highlight.scss";

// https://www.emgoto.com/react-table-of-contents/

//const emojiSupport = (text) => text.value.replace(/:\w+:/gi, (name) => emoji.getUnicode(name));

export default function MarkdownContent(props) {
	const [content, setContent] = useState();

	function a({ href, children, ...props }) {
		if (href.match(/^(https?:)?\/\//)) {
			return (
				<a href={href} target="_blank">
					{children}
				</a>
			);
		} else {
			return <Link to={href}>{children}</Link>;
		}
	}

	function li(elementProps) {
		let checkbox = null;
		if (elementProps.checked !== null && elementProps.checked !== undefined) {
			const checked = elementProps.checked;

			return (
				<li index={elementProps.index} className={elementProps.className}>
					<CheckboxFancy defaultChecked={checked} label={elementProps.children} />
				</li>
			);
		} else {
			return (
				<li index={elementProps.index} className={elementProps.className}>
					<span className={"content"}>{elementProps.children}</span>
				</li>
			);
		}
	}

	function img(elementProps) {
		let src = elementProps.src;
		let data = { alt: elementProps.alt, className: "" };
		try {
			data = JSON.parse(elementProps.alt);
		} catch (e) {}
		if (props.files) {
			for (const file of props.files) {
				if (file.filename === elementProps.src) {
					src = file.presigned_url;
				}
			}
		}
		return <img className={data.className} src={src} alt={data.alt} />;
	}

	// function Image(elementProps) {
	// 	let src = elementProps.src;
	// 	if ("page" in props.metadata && "files" in props.metadata.page) {
	// 		for (let index = 0; index < props.metadata.page.files.length; index++) {
	// 			if (encodeURI(props.metadata.page.files[index].filename) === elementProps.src) {
	// 				src = props.metadata.page.files[index].presigned_url;
	// 			}
	// 		}
	// 	}
	// 	return (
	// 		<>
	// 			<img alt={elementProps.alt} src={src} />
	// 		</>
	// 	);
	// }

	useEffect(() => {
		let text = props.children;
		text = text.replace(/:\w+:/gi, (match) => {
			const emojiName = match.slice(1, -1);
			return emoji.getUnicode(emojiName) || match;
		});
		setContent(text);
		//props.afterRender();
	}, [props.children]);

	useEffect(() => {
		//console.log("Content: " + content);
		if (props.afterRender && content) {
			props.afterRender();
		}
	}, [content]);

	return (
		<>
			<ReactMarkdown
				className="MarkdownContent"
				remarkPlugins={[remarkGfm]}
				rehypePlugins={[rehypeHighlight, rehypeSlug]}
				components={{ a, li, img }}
			>
				{content}
			</ReactMarkdown>
		</>
	);
}

/*
import React, { useEffect, useState, useRef } from "react";
import Markdown from "react-markdown/with-html";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { Link } from "react-router-dom";
import YAML from 'yaml';
import Handlebars from "handlebars";
import hljs from "highlight.js";
import "./MarkdownContent.scss";

export default function MarkdownContent(props) {
    const [blocks, setBlocks] = useState([])

    useEffect(() => {
        let blocks = [];
        try {
            let yamlDocs = YAML.parseAllDocuments(props.markdown)
            if (yamlDocs.length === 1) {
                //console.log("No metadata blocks identified within markdown")
                blocks.push({metadata: {}, markdown: props.markdown})
            } else if (yamlDocs.length % 2 === 0) {
                //console.log("Identified " + (yamlDocs.length / 2) + " block(s) within the markdown document")
                for (let index = 0; index < yamlDocs.length; index = index + 2) {
                    let block = {}
                    block.metadata = {...props.metadata, ...yamlDocs[index].toJSON()}
                    block.markdown = props.markdown.slice(yamlDocs[index+1].range[0], yamlDocs[index+1].range[1])
                    blocks.push(block)
                }
            } else {
                //console.warn("YAML/Markdown parser indentified a non-even number of YAML blacks; ignoring YAML frontmatter and embedded blocks")
                blocks.push({metadata: {...props.metadata}, markdown: props.markdown})
            }
        } catch(e) {
            blocks.push({metadata: {...props.metadata}, markdown: props.markdown})
        }
        setBlocks(blocks)
    }, [props.markdown, props.metadata]);

    return (
        <div className="MarkdownContent">
            { blocks.map((block, index) => (
                <MarkdownBlock key={index} markdown={block.markdown} metadata={block.metadata} />
            ))}
        </div>  
        );
}
MarkdownContent.propTypes = {
    markdown: PropTypes.string.isRequired,
    metadata: PropTypes.object
}
MarkdownContent.defaultProps = {
    metadata: {}
}

function MarkdownBlock(props) {
    const [classes, setClasses] = useState(["MarkdownBlock"])
    const [markdown, setMarkdown] = useState("")

    function Image(elementProps) {
        let src = elementProps.src
        if ("page" in props.metadata && "files" in props.metadata.page) {
            for (let index = 0; index < props.metadata.page.files.length; index++) {
                 if (encodeURI(props.metadata.page.files[index].filename) === elementProps.src) {
                     src = props.metadata.page.files[index].presigned_url
                 }
            }
        }
        return (
            <>
                <img alt={elementProps.alt} src={src} />
            </>
        )    
    }

    function Linker(elementProps) {
        let href = elementProps.href
        let isFile = false
        
        if ("page" in props.metadata && "files" in props.metadata.page) {
            for (let index = 0; index < props.metadata.page.files.length; index++) {
                 if (encodeURI(props.metadata.page.files[index].filename) === elementProps.href) {
                     href = props.metadata.page.files[index].presigned_url
                     isFile = true
                 }
            }
        }
        if (isFile || href.match(/^(https?:)?\/\//)) {
            return (
                <a href={href}>{elementProps.children}</a>
            );        
        } else {
            return (
                <Link to={href}>{elementProps.children}</Link>
            )
        }
    }

    function CodeBlock(elementProps) {
        const codeBlockElement = useRef(null)
        useEffect(() => {
            if ("language" in elementProps) {
                hljs.highlightBlock(codeBlockElement.current);
            }
        })

        return (
            <pre><code ref={codeBlockElement} className={`language-${elementProps.language}`}>{elementProps.value}</code></pre>
        )
    }

    function ListItem(elementProps) {
        let checkbox = null
        if (elementProps.checked !== null && elementProps.checked !== undefined) {
            const checked = elementProps.checked
            checkbox =
                <span className={"checkbox"}><label>
                        <input type="checkbox" checked={checked} readOnly={true}/>
                        <div><span></span></div>
                    </label></span>
        }
        return <li index={elementProps.index} className={classNames({"checkbox": !(elementProps.checked === null)})}>{checkbox}<span
            className={"content"}>{elementProps.children}</span></li>
    }

    useEffect(() => {
        // Apply handlebars templating with metadata
        let template = Handlebars.compile(props.markdown);
        let rendered = "Rendering ..."
        try {
            rendered = template(props.metadata)
        } catch(e) {
            rendered = props.markdown
        }

        // Deal with metadata
        var newClasses = ["MarkdownBlock"]
        if ("title" in props.metadata) {
            console.log("Setting document title from markdown metadata")
            document.title = "hx.io: " + props.metadata.title
        }
        if ("layout" in props.metadata) {
            newClasses.push(props.metadata.layout)
        }
        if ("class" in props.metadata) {
            newClasses.push(props.metadata.class)
        }
        setMarkdown(rendered);
        setClasses(newClasses);
    }, [props.markdown, props.metadata]);

    return (
        <div className={classNames(classes)}>
            <Markdown
                className="Markdown"
                source={markdown}
                skipHtml={false}
                escapeHtml={false}
                renderers={{code: CodeBlock, listItem: ListItem, link: Linker, image: Image}}
                plugins={[]}
            />
        </div>
    );
}
MarkdownBlock.propTypes = {
    markdown: PropTypes.string.isRequired,
    metadata: PropTypes.object
}
MarkdownBlock.defaultProps = {
    metadata: {}
}

*/
