import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHotkeys } from "react-hotkeys-hook";
import "./FancyModal.scss";

export default function FancyModal(props) {
	useHotkeys("esc", () => {
		props.onClose();
	});

	if (props.open) {
		return (
			<div className={"FancyModal"}>
				<div
					className={"Shade"}
					onMouseDown={() => {
						props.onClose();
					}}
				>
					<div
						className={"Modal"}
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
					>
						{props.children}
					</div>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}

FancyModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	// 	onKeyEnter: PropTypes.func,
	// 	onChange: PropTypes.func,
};
