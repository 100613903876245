import { useEffect, useState, useRef } from "react";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap, lineNumbers, highlightActiveLine } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { autocompletion, completionKeymap } from "@codemirror/autocomplete";
import { indentOnInput, bracketMatching } from "@codemirror/language";
import { indentWithTab, history, historyKeymap } from "@codemirror/commands";
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
import { languages } from "@codemirror/language-data";
import { vapor } from "./vapor";
import { browserSpellCheck } from "./browserspellcheck";
import type React from "react";
import emoji from "emoji-dictionary";
import "./style.scss";

interface Props {
	initialDoc: string;
	onChange?: (state: EditorState) => void;
}

function emojiCompletions(context) {
	let word = context.matchBefore(/:[\w]*/);
	if (!word) {
		return false;
	}
	if (word.from === word.to && !context.explicit) return null;
	let options = [
		// { label: "match", type: "keyword" },
		// { label: "hello", type: "variable", info: "(World)" },
		// { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" },
		// { label: ":smiley:", type: "text", apply: ":smiley:" },
	];

	for (let i = 0; i < emoji.names.length; i++) {
		options.push({
			label: ":" + emoji.names[i] + ":",
			type: "text",
			apply: ":" + emoji.names[i] + ":",
			detail: emoji.getUnicode(emoji.names[i]),
		});
		//console.log(emoji.names[i]);
	}
	return {
		from: word.from,
		options: options,
	};
}

const useCodeMirror = <T extends Element>(props: Props): [React.MutableRefObject<T | null>, EditorView?] => {
	const refContainer = useRef<T>(null);
	const [editorView, setEditorView] = useState<EditorView>();
	//const { onChange } = props;

	useEffect(() => {
		if (!refContainer.current) return;
		const startState = EditorState.create({
			doc: props.initialDoc,
			extensions: [
				keymap.of([...defaultKeymap, ...historyKeymap, ...completionKeymap, indentWithTab]),
				highlightActiveLine(),
				lineNumbers(),
				history(),
				indentOnInput(),
				bracketMatching(),
				highlightActiveLine(),
				markdown({
					base: markdownLanguage,
					codeLanguages: languages,
					addKeymap: true,
				}),
				browserSpellCheck(),
				vapor,
				autocompletion({ override: [emojiCompletions] }),
				EditorView.lineWrapping,
				EditorView.updateListener.of((update) => {
					if (update.changes && props.onChange) {
						props.onChange(update.state);
					}
				}),
			],
		});

		const view = new EditorView({
			state: startState,
			parent: refContainer.current,
		});
		setEditorView(view);
		//}, [refContainer]);
	}, []);

	return [refContainer, editorView];
};

export default useCodeMirror;
